<template>
	<div class="wrapper" :style="heightCssVars">
		<div :class="option1css" :style="selectedColorsCss" @click="optionSelected(option1)">{{ option1 }}</div>
		<div :class="option2css" :style="selectedColorsCss" @click="optionSelected(option2)">{{ option2 }}</div>
	</div>
</template>

<script>
export default {
	name: 'PillSelector',

	props: ['option1', 'option2', 'defaultSelection', 'dense', 'selectedBackground', 'selectedColor'],

	data: function () {
		return {
			selectedOption: this.defaultSelection === undefined ? this.option1 : this.defaultSelection,
		};
	},

	methods: {
		optionSelected(option) {
			this.$emit('option-selected', option);
			this.selectedOption = option;
		},
	},

	computed: {
		heightCssVars: function () {
			return {
				'--height': (this.dense ? 32 : 38) + 'px',
				'--line-height': (this.dense ? 30 : 36) + 'px',
				'--font-size': (this.dense ? 14 : 16) + 'px',
			};
		},

		selectedColorsCss: function () {
			return {
				'--background-color': this.selectedBackground ? this.selectedBackground : '#FFF',
				'--color': this.selectedColor ? this.selectedColor : this.$vuetify.theme.currentTheme.primary,
			};
		},

		option1css: function () {
			if (this.selectedOption === this.option1) {
				return 'selected';
			} else {
				return 'unselected';
			}
		},

		option2css: function () {
			if (this.selectedOption === this.option2) {
				return 'selected';
			} else {
				return 'unselected';
			}
		},
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 24px;
	background-color: var(--v-carrara_50-base);
	height: var(--height);
	line-height: var(--line-height);
	font-size: var(--font-size);
	font-family: stratos-semibold;
}

.selected {
	border-radius: 24px;
	width: 50%;
	background-color: var(--background-color);
	cursor: pointer;
	height: 100%;
	text-align: center;
	color: var(--color);
	transition: all 200ms ease;
}

.unselected {
	width: 50%;
	cursor: pointer;
	height: 100%;
	text-align: center;
}
</style>
