<template>
	<div :key="$store.getters.getClientId" class="pa-4">
		<domain-root type="Client" :id="$store.getters.getClientId" :show-deleted="showDeleted" :read-only="true"></domain-root>
	</div>
</template>

<script>
	import DomainRoot from '@/modules/files/DomainRoot';

	export default {
		name: 'MainFileSystem',

		props: [],

		components: { DomainRoot },

		data: function() {
			return {
				showDeleted: true,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
