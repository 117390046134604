<template>
	<div>
		<v-container fluid>
			<v-row
				class="font-12"
				style="background-color: var(--v-sidebar-base); border-radius: 4px"
				@contextmenu.stop.prevent="$refs.FileSystem.showContextMenu($event, null)"
			>
				<v-col cols="7" class="text-left brand-medium pointer row-format align-center" @click.stop="sort('name')"
					><div>Name</div>
					<v-icon v-if="sortBy === 'name'">{{
						sortDir === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'
					}}</v-icon></v-col
				>
				<v-col cols="2" class="text-left brand-medium pointer row-format align-center" @click.stop="sort('updated')"
					><div>Modified</div>
					<v-icon v-if="sortBy === 'updated'">{{
						sortDir === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'
					}}</v-icon></v-col
				>
				<v-col cols="2" class="text-left brand-medium pointer row-format align-center" @click.stop="sort('size')"
					><div>Size</div>
					<v-icon v-if="sortBy === 'size'">{{
						sortDir === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'
					}}</v-icon></v-col
				>
				<v-col cols="1" class="text-left brand-medium row-format align-center"><div>Ver</div></v-col>
			</v-row>
			<file-system
				ref="FileSystem"
				:sort-by="sortBy"
				:sort-dir="sortDir"
				:parent-folder-id="folderId"
				:show-deleted="showDeleted"
				:parent-deleted="false"
				:depth="0"
				:is-dragging="isDragging"
        :read-only="readOnly"
			></file-system>
		</v-container>
	</div>
</template>

<script>
	import FileSystem from '@/modules/files/FileSystem';
	export default {
		name: 'Files',

		props: {
			folderId: {
				type: String,
				default: null,
			},
      showDeleted: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
		},

		components: { FileSystem },

		data: function() {
			return {
				isDragging: false,
				sortBy: 'name',
				sortDir: 'asc',
			};
		},

		mounted() {
			document.addEventListener('dragstart', this.handleDragStart);
			document.addEventListener('dragover', this.handleDragOver);
			document.addEventListener('dragend', this.handleDragEnd);
			document.addEventListener('drop', this.handleDrop);
		},

		beforeDestroy() {
			document.removeEventListener('dragstart', this.handleDragStart);
			document.removeEventListener('dragover', this.handleDragOver);
			document.removeEventListener('dragend', this.handleDragEnd);
			document.removeEventListener('drop', this.handleDrop);
		},

		methods: {
			sort: function(key) {
				if (this.sortBy === key) {
					this.sortDir = this.sortDir === 'desc' ? 'asc' : 'desc';
				} else {
					this.sortBy = key;
					this.sortDir = 'asc';
				}
			},
			addNew: function() {
				this.$refs.FileSystem.handleUploadFile();
			},
			handleDragStart() {
				this.isDragging = true;
			},
			handleDragOver() {
				this.isDragging = true;
			},
			handleDragEnd() {
				this.isDragging = false;
			},
			handleDrop() {
				this.isDragging = false;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
