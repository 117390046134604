<template>
	<div id="deliverable-detail" class="column-format">
		<div class="modal-header row-format align-center">
			<div class="row-format align-center">
				<v-icon size="24">$projects</v-icon>
				<div style="margin-left: 8px">{{ project.name }}</div>
			</div>
			<div class="font-14">
				<v-btn class="ml-auto mr-n2" icon @click.stop="$emit('result', deliverable)">
					<v-icon size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>

    <div class="mb-4">
      <v-tabs v-model="tabItem">
        <v-tab>Overview</v-tab>
        <v-tab>Files</v-tab>
      </v-tabs>
    </div>

		<div v-if="tabItem === 0" id="deliverable-inner-wrapper" class="task-padding d-md-flex show-scrollbar">
			<div class="left-block">
				<v-textarea
					:rows="1"
					:disabled="isReadOnly"
					class="h-outline font-12"
					hide-details
					persistent-placeholder
					auto-grow
					v-model="deliverable.name"
					:placeholder="$t('projects.new-deliverable')"
					prepend-icon="$deliverableBox"
					style="font-size: 14px"
					:label="$t('projects.deliverable-name')"
				></v-textarea>

				<div class="text-left mt-3">
					<div class="font-grey font-12">{{ $t('projects.description') }}</div>
					<div class="mt-1 font-14">
						<markdown
							:value="deliverable.description"
							:id="deliverable.id"
							:placeholder="$t('projects.description-placeholder')"
							:input-only-mode="false"
							@update="deliverable.description = $event"
							:disabled="isReadOnly"
						/>
					</div>
				</div>

				<div class="mt-4 d-none d-md-block">
					<div class="label">{{ $t('projects.tasks') }}</div>
					<tasks ref="tasks" :deliverable="deliverable" :detail-view="true" :disabled="isReadOnly"></tasks>
				</div>

				<div class="mt-2" v-if="deliverable.approvalRequired">
					<div class="submitted" v-if="deliverable.approvalRequestedAt">
						{{ $t('projects.submitted') }} {{ DateTime.fromISO(deliverable.approvalRequestedAt).toFormat('DD') }}
					</div>
				</div>

				<div class="mt-4" v-if="deliverable.approvalRequired">
					<v-btn color="success" elevation="0" block @click="confirmApproval"
						>{{ $t('global.ok') }} <v-icon small class="ml-2">$success</v-icon></v-btn
					>
				</div>

				<div class="mt-4 d-none d-md-block" v-if="approvals.length">
					<div class="label">{{ $t('projects.client-workflow') }}</div>
					<ul v-for="approval in approvals" :key="approval.id" class="font-gray_80 font-12 text-left">
						<li>
							{{ approval.approverName }} {{ $t('global.on') }}
							{{ DateTime.fromISO(approval.approvedAt).toFormat('DD') }} {{ $t('global.from') }} [{{
								approval.approvalStatus
							}}] {{ $t('global.status') }}
						</li>
					</ul>
				</div>

				<div class="mt-4 d-none d-md-block" v-if="$vuetify.breakpoint.mdAndUp">
					<div class="label">{{ $t('projects.feed') }}</div>
					<activity ref="activity" :project="project" :deliverable="deliverable" :project-service="projectService" />
				</div>
			</div>
			<div class="right-block column-format gap-4">
				<div>
					<v-select
						class="h-outline"
						hide-details
						:disabled="isReadOnly"
						persistent-placeholder
						:label="$t('projects.filter.status')"
						:items="statusList"
						v-model="deliverable.statusId"
						item-text="label"
						item-value="id"
					>
						<template slot="append">
							<div class="v-input__icon v-input__icon--append">
								<div class="status-box" :style="`--status-color: ${currentStatus.hexColor}`"></div>
							</div>
						</template>
					</v-select>
				</div>

				<v-menu :close-on-click="true" :close-on-content-click="false" offset-y :disabled="isReadOnly">
					<template v-slot:activator="{ on }">
						<div v-on="on" class="pointer h-outline text-left pr-3 mb-2">
							<div class="font-12 font-gray_80">{{ $t('projects.assigned-to') }}</div>
							<div v-if="deliverable.assignedToList.length" class="row-format align-center">
								<assigned-user
									v-on="on"
									v-for="(assignedTo, index) in deliverable.assignedToList"
									:key="assignedTo"
									:assigned-to="assignedTo"
									:show-name="false"
									:assignable-users="assignableUsers"
									empty-label="--"
									:style="
										`${
											index > 0 ? 'margin-left: -6px' : ''
										}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
									"
								></assigned-user>
							</div>
							<div v-else>--</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div
							v-for="user in assignableUsers"
							:key="user.userId"
							class="row-format align-center text-left py-1 pointer"
						>
							<input
								style="width: 20px; height: 20px; border-radius: 4px"
								type="checkbox"
								:value="user.userId"
								v-model="deliverable.assignedToList"
							/>
							<assigned-user
								class="mx-2"
								v-if="user.userId"
								:assigned-to="user.userId"
								:show-name="true"
								:assignable-users="assignableUsers"
							></assigned-user>
						</div>
					</div>
				</v-menu>

				<date-selector
					:date="deliverable.startDate"
					:label="$t('projects.start')"
					@change="deliverable.startDate = $event"
					:disabled="isReadOnly"
				/>

				<date-selector
					:date="deliverable.dueDate"
					:label="$t('projects.due')"
					@change="deliverable.dueDate = $event"
					:disabled="isReadOnly"
				/>

				<div class="mt-4 d-md-none">
					<div class="label">{{ $t('projects.tasks') }}</div>
					<tasks ref="tasks" :deliverable="deliverable" :detail-view="true" :disabled="isReadOnly"></tasks>
				</div>

				<div class="mt-4 d-md-none" v-if="approvals.length">
					<div class="label">{{ $t('projects.client-workflow') }}</div>
					<ul v-for="approval in approvals" :key="approval.id" class="font-gray_80 font-12 text-left">
						<li>
							{{ approval.approverName }} {{ $t('global.on') }}
							{{ DateTime.fromISO(approval.approvedAt).toFormat('DD') }} {{ $t('global.from') }} [{{
								approval.approvalStatus
							}}] {{ $t('global.status') }}
						</li>
					</ul>
				</div>

				<div class="mt-4 d-md-none" v-if="$vuetify.breakpoint.smAndDown">
					<div class="label">{{ $t('projects.feed') }}</div>
					<activity ref="activity" :project="project" :deliverable="deliverable" :project-service="projectService" />
				</div>
			</div>
		</div>

		<div v-else id="deliverable-files" class=" d-md-flex show-scrollbar">
			<domain-root
				class="flex-grow-1"
				type="Task"
				:id="deliverable.id"
				:show-deleted="showDeleted"
				:read-only="false"
			></domain-root>
		</div>

		<div class="mt-auto modal-footer" v-if="isReadOnly">
			<v-btn class="primary-action ml-1" style="width: 160px" @click="$emit('result')">
				{{ $t('global.close') }}
			</v-btn>
		</div>
		<div class="mt-auto modal-footer" v-else>
			<v-btn class="secondary-action mr-1" icon @click="confirmDeleteDeliverable">
				<v-icon size="20">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" style="width: 160px" @click="saveAndCloseInternal">
				{{ $t('global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import Activity from '@/modules/projects/deliverable/Activity';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import { DateTime } from 'luxon';
	import ProjectService from '@/modules/projects/ProjectService';
	import marked from 'marked';
	import ConfirmModal from '@/components/ConfirmModal';
	import Markdown from '@/components/Markdown';
	import DateSelector from '@/components/DateSelector';
	import jsonPatch from 'fast-json-patch';
	import AssignedUser from '@/components/AssignedUser';
	import DomainRoot from '@/modules/files/DomainRoot';

	export default {
		name: 'DeliverableDetail',

		props: ['project', 'origDeliverable', 'statusList'],

		components: {
			Tasks,
			Activity,
			Markdown,
			DateSelector,
			AssignedUser,
			DomainRoot,
		},

		data: function() {
			return {
				tabItem: null,
				deliverable: JSON.parse(JSON.stringify(this.origDeliverable)),
				observer: null,
				isSaving: false,
				DateTime: DateTime,
				projectService: new ProjectService(),
				assignableUsers: [],
				showDeleted: false,
			};
		},

		mounted() {
			this.observer = jsonPatch.observe(this.deliverable);
			this.$store.state.eventBus.$on(this.getChannelName(), this.eventHandler);
			if (!this.isReadOnly) {
				this.getAssignableUsers();
			}
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.eventHandler);
		},

		methods: {
			getAssignableUsers: function() {
				this.projectService.getAssignableUsers(this.project.id).then((res) => {
					this.assignableUsers.splice(0, this.assignableUsers.length);
					this.assignableUsers.push(...res.data);
				});
			},

			checkClear: function(propName) {
				if (this.deliverable[propName] === '--clear--') {
					this.deliverable[propName] = null;
				}
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'Comment') {
					this.handleCommentEvent(event);
				}
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.pr-' + this.project.id + '.d-' + this.deliverable.id;
			},

			handleCommentEvent(event) {
				event.message.timestamp = new Date().toISOString().split('.')[0] + 'Z';
				let comment = event.message;
				console.log(comment);

				if (comment.edited) {
					let ix = this.deliverable.comments.findIndex((c) => c.id === comment.id);
					if (ix > -1) {
						if (comment.comment === '_deleted_') {
							this.deliverable.comments.splice(ix, 1);
						} else {
							this.deliverable.comments.splice(ix, 1, comment);
						}
					}
				} else {
					this.deliverable.comments.push(comment);
				}
			},

			saveAndCloseInternal: function() {
				this.$refs.tasks.clearEmpty();
				this.$refs.activity.checkCommentBeforeSave().finally(() => {
					this.isSaving = true;
					const patch = this.generatePatchFiltered();

					if (patch.length > 0) {
						this.$store.commit('startLoading');
						this.save(patch)
							.then()
							.catch()
							.finally(() => {
								this.$store.commit('stopLoading');
								this.isSaving = false;
								this.$emit('result', this.deliverable);
							});
					} else {
						this.$emit('result');
					}
				});
			},

			generatePatchFiltered: function() {
				const patch = jsonPatch.generate(this.observer, false);
				if (patch.length > 0) {
					for (let i = patch.length - 1; i >= 0; i--) {
						let path = patch[i].path;
						if (
							path.startsWith('/comments/') ||
							path.startsWith('/files/') ||
							path.startsWith('/events/') ||
							path.startsWith('/product')
						) {
							patch.splice(i, 1);
						}
					}
					return patch;
				} else {
					return [];
				}
			},

			save: function(patch) {
				this.$refs.tasks.clearEmpty();
				let result = this.projectService.patchDeliverable(this.deliverable.id, patch);

				return result
					.then((res) => {
						return Promise.resolve(res);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						return Promise.reject(err);
					});
			},

			confirmDeleteDeliverable: function() {
				let binding = {
					headingText: this.$t('global.confirm'),
					bodyText: this.$t('projects.delete-deliverable'),
					yesText: this.$t('global.delete'),
					noText: this.$t('global.cancel'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((confirm) => {
					if (confirm) {
						this.projectService.deleteDeliverable(this.deliverable.id).then(() => {
							this.deliverable.deleted = true;
							this.$emit('result', this.deliverable);
						});
					}
				});
			},

			confirmApproval: function() {
				let binding = {
					icon: '$success',
					severity: 'success',
					headingText: this.$t('projects.submit'),
					bodyText: this.$t('projects.confirm-approval'),
					yesText: this.$t('global.submit'),
					noText: this.$t('global.cancel'),
					noIsSuperAction: false,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((confirm) => {
					if (confirm) {
						this.$refs.activity.checkCommentBeforeSave().finally(() => {
							this.projectService.approveDeliverable(this.project.id, this.deliverable.id).then((res) => {
								this.deliverable = res.data;
							});
						});
					}
				});
			},

			formatMarkdown: function(comment) {
				if (comment) {
					let m = marked(comment, { breaks: true });
					m = m.replaceAll('<a href', '<a target="_blank" href');
					return m;
				} else {
					return '--';
				}
			},

			formatTime: function(number, type) {
				if (number < 0) {
					number = number * -1;
					return number + ' ' + type + (number > 1 ? 's' : '') + ' ' + this.$t('global.ago');
				} else {
					return number + ' ' + type + (number > 1 ? 's' : '') + ' ' + this.$t('global.remaining');
				}
			},
		},

		computed: {
			isReadOnly: function() {
				return this.project.portalAccess !== 'Full access';
			},

			currentStatus: function() {
				return this.statusList.find((s) => s.id === this.deliverable.statusId);
			},

			approvals: function() {
				if (this.deliverable.approvals && this.deliverable.approvals.length) {
					let result = [...this.deliverable.approvals];
					return result.reverse();
				} else {
					return [];
				}
			},
		},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.approval {
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		padding: 4px;
		margin-top: 4px;
		margin-bottom: 4px;
	}

	.modal-header {
		padding: 22px 24px 0;
		display: flex;
		justify-content: space-between;
	}

	.task-padding {
		padding: 8px 24px 24px;
	}

	#deliverable-detail {
		width: 700px;
		min-height: 500px;

		#deliverable-files {
			max-height: calc(100vh - 250px);
			overflow-y: auto;
			width: 100%;
		}

		#deliverable-inner-wrapper {
			max-height: calc(100vh - 250px);
			overflow-y: auto;

			.left-block {
				flex: 1 1 420px;
				max-width: 420px !important;
				word-break: break-all;
				padding-right: 20px;
			}

			.right-block {
				flex: 1 1 220px;
			}

			#deliverable-name {
				font-size: 18px !important;
				margin-top: 8px;
			}

			.label {
				color: var(--v-gray_80-base);
				font-size: 12px;
				text-align: left;
				margin-bottom: 8px;
			}
		}

		.time-worked {
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			height: 24px;
			padding: 0px 4px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: 18px;

			&:hover:not(.disabled) {
				background-color: var(--v-gray_10-base);
				cursor: pointer;
			}
		}
		//.timer-container.running-not-in-scope,
		//.timer-container.running-not-in-scope .radial-progress-container {
		//	opacity: 0.5;
		//	cursor: auto;
		//}

		.status-box {
			background-color: var(--status-color);
			width: 20px;
			height: 20px;
			border-radius: 4px;
		}

		.modal-footer {
			position: sticky;
			position: -webkit-sticky;
			margin-top: 8px;
		}
	}

	.submitted {
		background-color: var(--v-blue_10-base);
		font-size: 12px;
		padding: 4px 8px;
		border-radius: 4px;
		font-weight: 600;
		width: fit-content;
	}

	.description {
		//word-wrap: anywhere;
		word-break: break-all;
		overflow-wrap: break-word;
	}
</style>
