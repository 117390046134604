<template>
	<div
		@click="handleClick"
		v-if="isReady"
		:id="`service-package-${servicePackage.id}`"
		style="min-height: 40px; height:100%; position: relative"
		:style="`${wrapperStyle}`"
		class="column-format gap-6"
	>
		<editor
			v-if="active && editMode"
			ref="textEditor"
			:api-key="$store.getters.getTinyMceKey"
			:initial-value="servicePackage.description"
			:inline="true"
			:init="mceConfigText"
			@input="handleTextInput"
			:spellcheck="true"
		></editor>

		<div v-else v-html="tokenizedText"></div>

		<div
			v-if="items.length"
			class="column-format"
			:style="`border: 1px solid ${services.tableBorderColor}; border-radius: 4px;`"
		>
			<draggable v-model="items" :disabled="!editMode || !active">
				<div
					class="row-format align-start pa-2"
					v-for="(item, index) in items"
					:key="item.id"
					:style="`${index < items.length - 1 ? 'border-bottom: 1px solid' + services.tableBorderColor : ''}`"
				>
					<service-item-renderer class="flex-grow-1" :item="item" :tokens="tokensForItem(item)"></service-item-renderer>
					<v-icon
						v-if="editMode && active"
						class="pl-2 mt-1 ml-auto material-symbols-rounded pointer"
						size="18"
						@click.stop="editItem(item, $event)"
						color="gray_70"
						>edit</v-icon
					>
				</div>
			</draggable>
		</div>

		<v-menu v-if="active && editMode" v-model="lineItemMenu" :close-on-content-click="false">
			<template v-slot:activator="{ on }">
				<div class="font-primary pointer" v-on="on">+ Line item</div>
			</template>
			<products
				@select="
					addLineItem($event);
					lineItemMenu = false;
				"
				@close="lineItemMenu = false"
				:currency="agreement.cursor"
			></products>
		</v-menu>

		<div v-if="addOns.length" class="ml-1">
			<draggable v-model="addOns" :disabled="!editMode || !active">
				<div v-for="addOn in addOns" :key="addOn.id" class="column-format font-14 text-left" :id="`item-${addOn.id}`">
					<div class="row-format align-start pr-2">
						<v-switch
							:dark="false"
							class="flex-grow-1 custom-switch"
							:inset="true"
							v-model="addOn.selected"
							hide-details
							dense
							@click.stop
							:color="$store.getters.getCustomBranding.primaryColor"
							:disabled="addOnsDisabled"
							style="align-items: start!important;"
						>
							<template #label>
								<service-item-renderer
									class="flex-grow-1"
									:item="addOn"
									:tokens="tokensForItem(addOn)"
								></service-item-renderer>
							</template>
						</v-switch>

						<v-icon
							v-if="active && editMode"
							class="pl-2 mt-5 material-symbols-rounded pointer"
							size="18"
							@click.stop="editItem(addOn, $event)"
							color="gray_70"
							>edit</v-icon
						>
					</div>
				</div>
			</draggable>
		</div>

		<v-menu v-if="active && editMode" v-model="addOnMenu" :close-on-content-click="false">
			<template v-slot:activator="{ on }">
				<div class="font-primary pointer" v-on="on">+ Optional add-on</div>
			</template>
			<products
				@select="
					addLineItem($event, true);
					addOnMenu = false;
				"
				@close="addOnMenu = false"
				:currency="agreement.cursor"
			></products>
		</v-menu>

		<div :style="`width:100%; text-align: ${buttonAlignment}`" class="mt-auto" v-if="showButton">
			<v-btn
				:dark="false"
				class="elevation-3"
				:style="buttonStyle"
				:color="servicePackage.selected ? services.buttonColor : '#FFFFFF'"
				:x-small="button.xSmall"
				:small="button.small"
				:large="button.large"
				:x-large="button.xLarge"
				:block="button.block"
				@click.stop="handleSelection"
				:disabled="mainButtonDisabled"
			>
				<div class="pa-1 row-format centered" :style="`color: ${buttonFontColor}!important`">
					<div :style="`color: ${buttonFontColor}!important`">{{ servicePackage.buttonText }}</div>
					<span
						v-if="servicePackage.selected"
						class="material-symbols-rounded"
						:style="`font-size: 20px; color:#${buttonFontColor}!important`"
						>verified</span
					>
				</div>
			</v-btn>
		</div>

		<v-icon
			v-if="active && editMode"
			style="position:absolute; top: 10px; left: 10px; cursor: grab"
			size="20"
			color="gray_70"
			class="dragHandle"
			>drag_handle</v-icon
		>

		<v-menu v-if="active && editMode" :close-on-content-click="false" v-model="packageMenu">
			<template v-slot:activator="{ on }">
				<v-icon style="position:absolute; top: 10px; right: 10px" size="20" color="gray_50" class="pointer" v-on="on"
					>settings</v-icon
				>
			</template>
			<package-editor
				:agreement="agreement"
				:services="services"
				:service-package="servicePackage"
				@close="handlePackageEditorClose"
				@delete="$emit('delete', $event)"
			></package-editor>
		</v-menu>

		<!-- :attach="`#service-package-${servicePackage.id}`"-->

		<v-menu
			v-model="dialog"
			v-if="dialog"
			max-width="500"
			bottom
			left
			:nudge-bottom="menuY"
			:nudge-right="menuX"
			:close-on-content-click="false"
		>
			<div style="background-color: var(--v-white-base);min-width: 500px; max-width: 500px; width: 500px" class="pa-4">
				<service-item-editor
					:mce-config="mceConfig"
					:agreement="agreement"
					:service-package="servicePackage"
					:service-item="item"
					@delete="deleteItem($event)"
					@save="saveItem($event)"
					@close="dialog = false"
				></service-item-editor>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import { v4 as uuid4 } from 'uuid';
	import Products from '@/modules/invoices/InvoiceRenderer/Products';
	import { DateTime } from 'luxon';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';
	import PackageEditor from '@/modules/agreements/schema/PackageEditor';
	import ServiceItemEditor from '@/modules/agreements/schema/ServiceItemEditor';
	import ServiceItemRenderer from '@/modules/agreements/schema/ServiceItemRenderer';
	import Draggable from 'vuedraggable';
	import marked from 'marked';

	export default {
		name: 'ServicePackage',

		props: [
			'active',
			'agreement',
			'services',
			'servicePackage',
			'mceConfig',
			'editMode',
			'clientMode',
			'tokens',
			'signer',
			'templateMode',
		],

		components: {
			ServiceItemRenderer,
			ServiceItemEditor,
			PackageEditor,
			Products,
			Draggable,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		mixins: [AgreementMixin],

		data: function() {
			return {
				//element: { ...this.servicePackage },
				mceConfigText: null,
				isEditorFocused: false,
				isEditorActive: false,
				isReady: false,
				packageMenu: false,
				lineItemMenu: false,
				addOnMenu: false,
				dialog: false,
				menuX: 0,
				menuY: 0,
				menuWidth: 0,
				item: null,
				items: [],
				addOns: [],

				initialAiContext: [
					'You are helping to write content for a proposal.',
					'Feel free to ask clarifying questions.',
					'Once you have gathered enough information from the user, please output a nicely formatted version and prompt the user to click the "Create" button if they are happy with result.',
				],

				initialAiPrompt: 'Please tell me a bit about what I can help you write today.',
				finalizationPrompt: `Please return the final copy nicely formatted without any explanations or additional text.  No additional prompts to the user should be included.`,
			};
		},

		mounted() {
			this.items = [...this.servicePackage.items.filter((i) => !i.addOnItem || (i.addOnItem && !i.optional))];
			this.addOns = [...this.servicePackage.items.filter((i) => i.addOnItem && i.optional)];

			this.mceConfigText = JSON.parse(JSON.stringify(this.mceConfig));
			this.mceConfigText.contextmenu = 'paste | link | table | assistant';
			this.mceConfigText.setup = (editor) => {
				let items = [];
				this.packageTokens.forEach((value, key) => {
					items.push({
						type: 'menuitem',
						text: key,
						onAction: function() {
							editor.insertContent('{{' + key + '}}');
						},
					});
				});

				editor.ui.registry.addNestedMenuItem('assistant', {
					text: '✨Assistant',
					getSubmenuItems: () => {
						return [
							{
								type: 'menuitem',
								text: 'Elaborate',
								onAction: () => {
									this.rewrite('elaborate');
								},
							},
							{
								type: 'menuitem',
								text: 'Simplify',
								onAction: () => {
									this.rewrite('simplify');
								},
							},
							{
								type: 'menuitem',
								text: 'Make more formal',
								onAction: () => {
									this.rewrite('formalize');
								},
							},
							{
								type: 'menuitem',
								text: 'Make friendlier',
								onAction: () => {
									this.rewrite('friendlier');
								},
							},
							{
								type: 'menuitem',
								text: 'Summarize',
								onAction: () => {
									this.rewrite('summarize');
								},
							},
							{
								type: 'menuitem',
								text: 'Paraphrase',
								onAction: () => {
									this.rewrite('paraphrase');
								},
							},
						];
					},
				});

				editor.ui.registry.addMenuButton('tokenbutton', {
					text: 'Tokens',
					fetch: function(callback) {
						callback(items);
					},
				});

				editor.on('focus', () => {
					this.editorActive(true);
				});

				editor.ui.registry.addButton('ai', {
					text: '✨Assistant',
					onAction: () => {
						this.aiHelper();
					},
				});

				editor.on('blur', () => {
					this.editorActive(false);
					this.$emit('change', this.servicePackage);
				});
			};
			this.isReady = true;
      this.handleNoProduct();
		},

		beforeDestroy() {},

		methods: {
			async rewrite(modifier) {
				const { default: AiAssistantService } = await import('@/modules/ai/AiAssistantService');
				let aiAssistantService = new AiAssistantService();
				let editor = this.$refs.textEditor.editor;
				let selection = editor.selection.getContent({ format: 'html' });

				if (selection) {
					this.$store.commit('startLoading');
					aiAssistantService
						.rewrite(selection, modifier)
						.then((response) => {
							editor.selection.setContent(response);
							this.text = editor.getContent();
							let element = { ...this.item };
							element.text = this.text;
							this.$emit('change', element);
						})
						.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			setReadOnly: function(readOnly = true) {
				if (this.$refs.textEditor && this.$refs.textEditor.editor) {
					this.$refs.textEditor.editor.setMode(readOnly ? 'readonly' : 'design');
				}
			},

			async aiHelper() {
				const { default: AiChatWidget } = await import('@/modules/ai/AiChatWidget');
				let binding = {
					maxHeight: 'calc(100vh - 350px)',
					context: this.initialAiContext,
					initialPrompt: this.initialAiPrompt,
					finalizationPrompt: this.finalizationPrompt,
					useCase: 'GeneralChat',
					model: 'gpt-4o',
					resultFormat: 'text',
				};
				this.$store.state.globalModalController.openModal(AiChatWidget, binding).then((res) => {
					if (res) {
						let m = marked(res, { breaks: true });
						m = m.replaceAll('<a href', '<a target="_blank" href');

						const editor = this.$refs.textEditor.editor;
						const currentContent = editor.getContent();
						editor.setContent(currentContent + m);
						this.servicePackage.description = currentContent + m;
						this.$emit('change', this.servicePackage);
					}
				});
			},

			editItem: function(item, pointerEvent) {
				this.dialog = true;
				this.item = item;
				//let div = document.getElementById(`service-package-${this.servicePackage.id}`).getBoundingClientRect();
				this.menuX = pointerEvent.clientX;
				this.menuY = pointerEvent.clientY;
				console.log(pointerEvent);
				console.log(this.menuX);
				console.log(this.menuY);
			},

			deleteItem: function(item) {
				let list = item.addOnItem && item.optional ? this.addOns : this.items;
				let ix = list.findIndex((i) => i.id === item.id);
				if (ix > -1) {
					list.splice(ix, 1);
				}
			},

			saveItem: function(item) {
				let list = item.addOnItem && item.optional ? this.addOns : this.items;

				let ix = list.findIndex((i) => i.id === item.id);
				if (ix > -1) {
					list.splice(ix, 1, item);
				}
        item.product.description = item.description;
			},

			addLineItem: function(result, optional = false) {
				let product = result.product;

				let currencyRate = result.product.currencyRates.find((r) => r.currency === this.agreement.currency);

				let serviceItem = {};
				serviceItem.id = uuid4();
				serviceItem.description = product.productName + (product.description ? '\n' + product.description : '');
				serviceItem.product = product;
				serviceItem.quantity = 1;
				serviceItem.rate = currencyRate ? currencyRate.rate : product.rate;
				serviceItem.taxable = product.taxable;
				serviceItem.relativeDueDate = true;
				serviceItem.dueDate = null;
				serviceItem.dueDateRelative = { duration: null, timeUnit: 'WEEKS' };
				serviceItem.addOnItem = true;
				serviceItem.oneTimeFee = true;
				serviceItem.optional = optional;
				serviceItem.format = 'Markdown';

				let list = serviceItem.addOnItem && serviceItem.optional ? this.addOns : this.items;
				list.push(serviceItem);
			},

			tokensForItem(item) {
				let quantity = item.quantity ? item.quantity : 0;
				let rate = item.rate ? item.rate : 0;

				let tokens = new Map();
				tokens.set('Item.Product', item.product?.name);
				tokens.set('Item.Quantity', item.quantity ? item.quantity : '');

				if (item.rate) {
					tokens.set(
						'Item.Rate',
						item.rate ? this.$formatters.dollars(item.rate, true, true, this.agreement.currency) : ''
					);
					tokens.set('Item.Total', this.$formatters.dollars(quantity * rate, true, true, this.agreement.currency));
				} else {
					tokens.set('Item.Rate', '');
					tokens.set('Item.Total', '');
				}
				tokens.set('Item.DueDate', this.dueDateFormatted(item));

				if (item.taxable) {
					let totalTax = quantity * rate * (this.taxRate / 100);
					tokens.set('Item.TaxLabel', this.taxLabel);
					tokens.set('Item.TaxRate', this.taxRate + '%');
					tokens.set('Item.Tax', this.$formatters.dollars(totalTax, true, true, this.agreement.currency));
					tokens.set(
						'Item.TotalWithTax',
						this.$formatters.dollars(totalTax + quantity * rate, true, true, this.agreement.currency)
					);
				} else {
					tokens.set('Item.TaxLabel', this.taxLabel);
					tokens.set('Item.TaxRate', '0%');
					tokens.set('Item.Tax', this.$formatters.dollars(0, true, true, this.agreement.currency));
					tokens.set(
						'Item.TotalWithTax',
						this.$formatters.dollars(quantity * rate, true, true, this.agreement.currency)
					);
				}
				return tokens;
			},

			handleSelection: function() {
				this.servicePackage.selected = !this.servicePackage.selected;
				if (!this.servicePackage.selected) {
					this.servicePackage.items.forEach((a) => (a.selected = false));
				}

				if (this.services.selectionType === 'SINGLE') {
					for (let i = 0; i < this.services.packages.length; i++) {
						let pkg = this.services.packages[i];
						if (pkg.id === this.servicePackage.id) {
							continue;
						}
						pkg.selected = false;
						pkg.items.forEach((a) => (a.selected = false));
					}
				}
			},

			handlePackageEditorClose: function() {
				this.$emit('change', this.servicePackage);
				this.packageMenu = false;
			},

			handleClick: function(event) {
				this.isEditorActive = true;
				this.$emit('click', event);
			},

			editorActive: function(value) {
				this.isEditorActive = value;
				this.$store.state.eventBus.$emit('drag-enabled', !value);
				this.$store.state.eventBus.$emit('undo-enabled', !value);
			},

			handleTextInput: function() {
				this.servicePackage.description = this.$refs.textEditor.editor.getContent();
			},

			dueDateFormatted: function(item) {
				if (item.dueDate !== null && !item.relativeDueDate) {
					return DateTime.fromISO(item.dueDate).toLocaleString(DateTime.DATE_MED);
				} else if (item.dueDateRelative && item.dueDateRelative.duration && item.dueDateRelative.timeUnit) {
					let unit = item.dueDateRelative.timeUnit.toLowerCase();
					return this.$tc('global.' + unit, item.dueDateRelative.duration);
				} else {
					return '';
				}
			},

			syncItemsAndAddOns: function() {
				this.servicePackage.items.splice(0);
				this.servicePackage.items.push(...this.items);
				this.servicePackage.items.push(...this.addOns);
			},

      handleNoProduct: function() {
        this.items.forEach(item => {
          if (!item.product) {
            item.product = {
              description: item.description,
              productName: item.description.split(/\n|<br>/)[0].replace(/<\/?p>/g, ""),
              descriptionFormat: "HTML",
              id: uuid4()
            };
          }
        });
      }
		},

		watch: {
			active: function(val) {
				if (!val) {
					this.packageMenu = false;
				}
			},

			items: {
				deep: true,
				handler: function() {
					this.syncItemsAndAddOns();
				},
			},

			addOns: {
				deep: true,
				handler: function() {
					this.syncItemsAndAddOns();
				},
			},

			servicePackage: {
				deep: true,
				handler: function() {
					if (!this.isEditorActive) {
						this.$emit('change', this.servicePackage);
					}
				},
			},
		},

		computed: {
			addOnsDisabled: function() {
				return (
					(!this.servicePackage.selected && !this.isSinglePackageAndRequired) ||
					this.agreement.fullyExecuted ||
					(this.signer && this.signer.locked) ||
					this.mainButtonDisabled
				);
			},

			mainButtonDisabled: function() {
				if (this.editMode) {
					return false;
				}
				return (
					this.agreement.fullyExecuted ||
					(this.signer && this.signer.locked) ||
					(!this.clientMode && this.agreement.clientSigned)
				);
			},

			isSinglePackageAndRequired: function() {
				return this.services.packages.length === 1 && this.services.selectionRequired;
			},

			showButton: function() {
				return !this.isSinglePackageAndRequired;
			},

			currency: function() {
				return this.getCurrency(this.agreement);
			},

			taxRate: function() {
				return this.getTaxRate(this.agreement);
			},

			taxLabel: function() {
				return this.getTaxLabel(this.agreement);
			},

			buttonFontColor: function() {
				if (!this.servicePackage.selected) {
					if (this.mainButtonDisabled) {
						return this.$vuetify.theme.dark ? '#7F7F7F' : '#FFF';
					} else {
						return this.services.buttonColor;
					}
				} else {
					let bgColor = this.services.buttonColor;
					let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
					let r = parseInt(color.substring(0, 2), 16); // hexToR
					let g = parseInt(color.substring(2, 4), 16); // hexToG
					let b = parseInt(color.substring(4, 6), 16); // hexToB
					return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
				}
			},

			wrapperStyle: function() {
				let style = `padding: ${this.services.padding}px; `;

				if (this.services.backgroundColor) {
					style = style + `background-color: ${this.services.backgroundColor}; `;
				}
				if (this.services.borderColor) {
					style = style + `border-color: ${this.services.borderColor}; `;
				}
				if (this.services.borderWidth) {
					style = style + `border-style: solid; border-width: ${this.services.borderWidth}px; `;
				}
				if (this.services.borderRadius) {
					style = style + `border-radius: ${this.services.borderRadius}px; `;
				}

				return style;
			},

			buttonStyle: function() {
				if (this.mainButtonDisabled) {
					if (this.servicePackage.selected) {
						return `border: 1px solid ${this.services.buttonColor}; background-color: ${this.services.buttonColor}!important`;
					} else {
						return ``;
					}
				} else {
					return `border: 1px solid ${this.services.buttonColor}`;
				}
			},

			button: function() {
				return {
					xSmall: this.services.buttonSize === 'X-Small',
					small: this.services.buttonSize === 'Small',
					large: this.services.buttonSize === 'Large',
					xLarge: this.services.buttonSize === 'X-Large',
					block: this.services.buttonAlignment === 'block',
				};
			},

			buttonAlignment: function() {
				if (this.services.buttonAlignment === 'block') {
					return 'unset';
				} else {
					return this.services.buttonAlignment;
				}
			},

			packageTokens: function() {
				let result = new Map();
				result.set('Package.Name', this.servicePackage.packageName);
				result.set('Package.Total', this.$formatters.dollars(this.packageTotal, true, true, this.currency));
				result.set('Package.TotalWithTax', this.$formatters.dollars(this.packageTotalWithTax, true, true, this.currency));
				result.set('Package.Tax', this.$formatters.dollars(this.packageTax, true, true, this.currency));
				result.set('Package.TaxRate', this.taxRate);
				result.set('Package.FeeLabel', this.feeLabel);
				result.set(
					'Package.StartDate',
					this.servicePackage.startDate
						? DateTime.fromISO(this.servicePackage.startDate).toLocaleString(DateTime.DATE_MED)
						: '--'
				);
				result.set('Package.HourEstimateMin', this.servicePackage.fees.estimateMin);
				result.set('Package.HourEstimateMax', this.servicePackage.fees.estimateMax);
				result.set(
					'Package.DepositPercent',
					this.servicePackage.fees.feeType === 'Hourly' || this.servicePackage.fees.feeType === 'Per Item'
						? this.servicePackage.depositAmount
						: 'N/A'
				);
				result.set(
					'Package.HourlyOverage',
					this.$formatters.dollars(this.servicePackage.fees.retainerOverageRate, true, true, this.currency)
				);
				result.set('Package.DepositAmount', this.depositAmount);
				result.set('------------------------', null);

				if (this.tokens) {
					this.tokens.forEach((value, key) => {
						if (this.templateMode) {
							result.set(key, '{{' + key + '}}');
						} else {
							result.set(key, value);
						}
					});
				}

				return result;
			},

			packageTotal: function() {
				return this.getPackageTotalPreTax(this.agreement, this.servicePackage);
			},

			packageTotalWithTax: function() {
				return this.getPackageTotalWithTax(this.agreement, this.servicePackage);
			},

			packageTax: function() {
				return this.getPackageTax(this.agreement, this.servicePackage);
			},

			feeLabel: function() {
				return this.getFeeLabel(this.servicePackage);
			},

			depositAmount: function() {
				return this.getDepositAmount(this.servicePackage);
			},

			tokenizedText: function() {
				if (!this.servicePackage.description) {
					return null;
				}

				let text = this.servicePackage.description;

				this.packageTokens.forEach((value, key) => {
					text = text.replaceAll(`{{${key}}}`, value);
				});

				return text;
			},
		},
	};
</script>

<style lang="scss">
	.custom-switch {
		.v-input__slot {
			align-items: start!important;
		}

		&:not(.v-input--is-disabled) {
			.v-input--switch__track {
				color: var(--primary-brand-color) !important; // Replace "black" with your desired hex color
				background-color: var(--primary-brand-color) !important; // Replace "black" with your desired hex color
				opacity: 0.5 !important;
			}
		}
		&.v-input--is-disabled {
			.v-input--switch__track {
				color: var(--primary-brand-color) !important; // Replace "black" with your desired hex color
				background-color: var(--primary-brand-color) !important; // Replace "black" with your desired hex color
				opacity: 0.3 !important;
			}
		}
	}
</style>

<style scoped lang="scss">
	.line-item-table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;

		tbody {
			tr {
				td {
					vertical-align: top;
					text-align: right;
					border-bottom: 1px solid var(--v-table-border);
					border-top: 1px solid var(--v-table-border);
					border-left: 1px solid var(--v-table-border);
					padding: 6px;
				}

				td:nth-child(1) {
					vertical-align: top;
					text-align: left;
					padding-left: 8px;
				}

				td:last-child {
					padding-right: 8px;
					border-right: 1px solid var(--v-table-border);
				}
			}
		}
	}

	.description {
		font-weight: 400;
		div::first-line {
			font-weight: 600;
		}
	}
</style>
