<template>
	<div class="text-left" style="color: var(--body-color)!important; font-size: var(--body-size) !important">
		<div class="row-format gap-3">
			<div>
          <div v-html="item.description"></div>
				<div class="font-14" v-if="tokens.get('Item.DueDate')">{{ tokens.get('Item.DueDate') }}</div>
			</div>
			<div class="ml-auto text-right">
				<span style="font-weight: 600">{{ tokens.get('Item.Total') }}</span
				><br />
				<span v-if="item.taxable && item.quantity && item.rate" class="font-14">+{{ tokens.get('Item.TaxRate') }} {{ tokens.get('Item.TaxLabel') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import marked from "marked";

	export default {
		name: 'ServiceItemRenderer',

		props: ['item', 'tokens'],

		components: {},

		data: function() {
			return {};
		},

    mounted() {
      this.initialize();
    },

		beforeDestroy() {},

		methods: {
      initialize: function() {
        this.convertMarkdown();
      },

      convertMarkdown: function() {
        if (this.item && this.item.format === 'Markdown' && this.item.description) {
          this.item.description = this.formatMarkdown(this.item.description);
          this.item.format = 'HTML';
        }
      },

      formatMarkdown: function(description) {
        if (description) {
          let m = marked(description, { breaks: true });
          m = m.replaceAll('<a href', '<a target="_blank" href');
          return m;
        } else {
          return null;
        }
      },
    },

		computed: {},
	};
</script>

<style scoped lang="scss">

</style>
