<template>
	<div class="proposal-deliverable-wrapper">
		<v-row @click="handleClick()" :class="classList" @mouseover="hovering = true" @mouseleave="hovering = false" :style="index === 0 ? 'border-top: 1px solid var(--v-gray_50-base);' : ''">
			<v-col class="deliverable" :cols="mainDeliverableColumns" style="align-items: center" align="left">
				<v-btn icon x-small class="deliverableHandle mr-1" @click.prevent="() => {}" v-if="editMode">
					<v-icon size="20" v-if="editMode">$drag</v-icon>
				</v-btn>
				<div class="column-format">
					<div class="brand-medium">{{ deliverableLabel }}</div>
					<div v-if="descriptionLabel" class="font-gray_80" style="white-space: pre-wrap">{{ descriptionLabel }}</div>
				</div>
			</v-col>
			<v-col class="deliverable" :style="$vuetify.breakpoint.xsOnly ? '' : 'justify-content: flex-end; text-align: right'" cols="12" sm="3" v-if="showPricing"><div v-html="deliverablePricing"></div></v-col>
			<v-col class="deliverable" :style="$vuetify.breakpoint.xsOnly ? '' : 'justify-content: flex-end'" cols="12" sm="2" v-if="showDueDate"><div>{{ dueDateFormatted }}</div></v-col>
		</v-row>

		<basic-modal v-if="isReady" :dialog="edit" :persistent="true" :max-width="565" @close="cancelEdit()">
			<div id="proposal-deliverable-modal">
				<v-btn icon class="close-dialog" @click="cancelEdit()">
					<v-icon>$close</v-icon>
				</v-btn>
				<v-form @keyup.native.enter.stop>
					<div class="modal-padding">
						<div class="modal-title" v-if="deliverable.initialSetupTask">{{ $t('proposal.deliverables.setup') }}</div>
						<div class="modal-title" v-else>{{ $t('proposal.deliverables.deliverable') }}</div>


						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="deliverable.initialSetupTask ? $t('proposal.deliverables.fee-name') : $t('proposal.deliverables.name')"
							v-model="deliverable.deliverable"
						>
						</v-text-field>

						<v-textarea
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('proposal.deliverables.description')"
							v-model="deliverable.description"
							auto-grow
							rows="1"
						></v-textarea>

						<div v-if="showProductSelect">
							<per-item-pricing
								:hide-icon="true"
								:product="deliverable.product"
								:quantity="deliverable.quantity"
								:editable="true"
								:currency="currency"
								@product-selected="handleProductSelected($event)"
								@quantity-updated="handleQuantityUpdated($event)"
							></per-item-pricing>
							<v-switch
									:label="$t('proposal.deliverables.show-pricing-detail')"
									v-model="deliverable.showPricingDetail"
									inset
									dense
									hide-details
									class="mt-2 date-type-switch"
							></v-switch>
						</div>

						<v-switch
							:label="$t('proposal.content.relative-date')"
							v-model="dateTypeSwitch"
							inset
							dense
							hide-details
							class="mt-2 date-type-switch"
							@change="handleDateTypeSwitch"
						></v-switch>

						<template v-if="dateType === 'RELATIVE'">
							<div class="label-style">{{ $t('proposal.content.how-soon') }}</div>
							<div class="d-flex align-center justify-space-between half" style="gap: 12px">
								<v-text-field
									type="number"
									inputmode="number"
									min="0"
									:placeholder="
										$t('templates.packages.deliverable-detail-modal.duration-placeholder', {
											units: humanUnits,
										})
									"
									hide-details
									class="h-outline-no-label mb-0"
									persistent-placeholder
									v-model="deliverable.dueDateRelative.duration"
									:disabled="dateType === 'FIXED'"
									@keydown="handleDurationKeydown($event)"
								/>
								<v-select
									:placeholder="$t('templates.packages.deliverable-detail-modal.units-placeholder')"
									hide-details
									class="h-outline-no-label mb-0"
									persistent-placeholder
									:items="timeUnits"
									item-value="value"
									item-text="text"
									v-model="deliverable.dueDateRelative.timeUnit"
									:disabled="dateType === 'FIXED'"
								/>
							</div>
						</template>

						<template v-if="dateType === 'FIXED'">
							<div class="label-style">{{ $t('proposal.content.fixed-date') }}</div>

							<v-menu
								ref="menu"
								v-model="dueDateMenu"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="290px"
								:disabled="dateType === 'RELATIVE'"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										class="h-outline-no-label pr-2 mb-0"
										hide-details
										persistent-placeholder
										:placeholder="$t('proposal.deliverables.delivery-date')"
										:value="dueDateFormattedInput"
										readonly
										v-bind="attrs"
										v-on="on"
									>
										<template v-slot:append>
											<div class="clear-date-icon">
												<v-icon
													v-if="deliverable.dueDate !== null"
													@click.native="deliverable.dueDate = null"
													>$clear</v-icon
												>
											</div>
											<div class="due-date-icon">
												<v-icon
													@click.native="dueDateMenu = true"
													:class="'icon-two-color' + (dateType === 'RELATIVE' ? ' disabled' : '')"
													>$calendar</v-icon
												>
											</div>
										</template>
									</v-text-field>
								</template>
								<v-date-picker
									:first-day-of-week="$store.getters.firstDayOfWeek"
									v-model="deliverable.dueDate"
									@change="dueDateEdited()"
									no-title
									scrollable
								>
								</v-date-picker>
							</v-menu>
						</template>
					</div>

					<div class="modal-footer">
						<v-btn class="secondary-action mr-1" @click.prevent="deleteDialog=true">
							<v-icon color="gray_70" size="20">$delete</v-icon>
						</v-btn>
						<v-btn class="primary-action ml-1" :width="120" @click.prevent="saveEdit()">
							{{ $t('global.save') }}
						</v-btn>
					</div>
				</v-form>
			</div>
		</basic-modal>

		<basic-modal :dialog="deleteDialog" :persistent="false" :max-width="290" @close="cancelDelete()">
			<div id="delete-deliverable-modal">
				<v-btn icon class="close-dialog" @click="cancelDelete()">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="px-10 py-6">
					<div class="brand-semilight font-16">{{ $t('proposal.deliverables.delete-confirm') }}</div>
				</div>
				<div class="modal-footer">
					<v-btn color="primary-action mr-1" width="100" @click="cancelDelete()">{{ $t('global.no') }}</v-btn>
					<v-btn color="secondary-action ml-1" width="100" @click="doDelete()">{{ $t('global.yes') }}</v-btn>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
  import {DateTime} from 'luxon';
	import BasicModal from '@/components/BasicModal';
	import PerItemPricing from '@/modules/projects/deliverable/PerItemPricing';

	export default {
		name: 'Deliverable',
		components: { PerItemPricing, BasicModal },
		props: ['feeSchedule','index','deliverable', 'editMode', 'hasOptional', 'showDueDate','showPricing','showProductSelect','mainDeliverableColumns','currency','taxLabel','taxRate'],

		data: function () {
			return {
				// item: this.deliverable,
				backup: null,
				edit: false,
				menu: false,
				deleteDialog: false,
				productDialog: false,
				hovering: false,
				dueDateMenu: false,
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
				dateType: null,
				dateTypes: ['RELATIVE', 'FIXED'],
				dateTypeSwitch: null,
				isReady: false,
				products: [],
				dueDateCols: 2,
				priceCols: 2,
			};
		},

		mounted() {
			if (this.deliverable.dueDate) {
				this.dateType = 'FIXED';
				this.dateTypeSwitch = false;
			} else {
				this.dateType = 'RELATIVE';
				this.dateTypeSwitch = true;
			}
			if (this.showProductSelect && !this.deliverable.fee) {
				this.deliverable.fee = {};
			}
			if (!this.deliverable.dueDateRelative) {
				// this.$nextTick(() => {
				this.deliverable.dueDateRelative = { duration: null, dateUnit: null };
				// });
			}
			if (this.deliverable.isNew) {
				this.deliverable.isNew = false;
				this.handleClick();
			}
			this.isReady = true;
		},

		methods: {
			notifyChange: function () {
				if (this.dateType === 'RELATIVE') {
					this.deliverable.dueDate = null;
				} else if (this.dateType === 'FIXED') {
					this.deliverable.dueDateRelative.timeUnit = null;
					this.deliverable.dueDateRelative.duration = null;
				}
				this.$emit('changed');
			},

			handleProductSelected: function(product){
				this.deliverable.product = product;
				if(product) {
					if (!this.deliverable.deliverable) {
						this.deliverable.deliverable = product.productName;
					}
					if (!this.deliverable.description) {
						this.deliverable.description = product.description;
					}
				}
				this.$emit('changed');
			},

			handleQuantityUpdated: function(quantity){
				this.deliverable.quantity = quantity;
				this.$emit('changed');
			},

			handleClick: function () {
				if (this.editMode) {
					this.backup = JSON.parse(JSON.stringify(this.deliverable));
					this.edit = true;
				} else if (this.deliverable.optional) {
					this.deliverable.selected = !this.deliverable.selected;
				}
			},

			handleSelectProduct: function (product) {
				this.deliverable.product = product;
				if (this.overrideDeliverableName()) {
					this.deliverable.deliverable = product.productName;
				}
				this.productDialog = false;
			},
			setProducts: function (products) {
				this.products = products;
				console.log(products);
			},
			overrideDeliverableName: function () {
				if (!this.deliverable.deliverable) {
					return true;
				}
				if (this.products.findIndex((p) => p.productName === this.deliverable.deliverable) > -1) {
					return true;
				}
				return false;
			},

			dueDateEdited: function () {
				this.deliverable.dueDate = this.deliverable.dueDate + 'T12:00:00Z';
				this.dueDateMenu = false;
			},

			saveEdit: function () {
				this.backup = null;
				this.edit = false;
				this.notifyChange();
			},

			cancelEdit: function () {
				for (const property in this.backup) {
					this.deliverable[property] = this.backup[property];
				}
				this.edit = false;
				this.notifyChange();
			},

			confirmDelete(event) {
				event.stopPropagation();
				this.deleteDialog = true;
			},

			cancelDelete() {
				this.deleteDialog = false;
			},

			doDelete() {
				this.$emit('delete');
				this.deleteDialog = false;
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			handleDateTypeSwitch() {
				if (this.dateTypeSwitch) {
					this.dateType = 'RELATIVE';
				} else {
					this.dateType = 'FIXED';
				}
			},



		},

		computed: {
			classList: function () {
				if (this.editMode || this.deliverable.optional) {
					return 'py-1 baseDeliverableWrapper deliverableWrapper editableDeliverable';
				} else {
					return 'py-1 baseDeliverableWrapper';
				}
			},

			deliverablePricing: function(){
				let product = this.deliverable.product;

				if(this.feeSchedule.feeType !== 'Per Item' && !this.deliverable.initialSetupTask){
					return this.$t('proposal.deliverables.included');
				}

				if(product){
					let quantity = this.deliverable.quantity ? this.deliverable.quantity : 0;
					let total = this.$formatters.dollars((quantity * product.rate), true, true, this.currency);
					let result = `<span class="brand-medium">${total}</span>`;

					if(product.taxable && this.taxRate){
						result = result + `<span class="font-12"> +${this.taxRate}% ${this.taxLabel}</span>`;
					}

					if(this.deliverable.showPricingDetail){
						result = result + `<br><span class="font-gray_70">${product.hourly ? '~': ''}${quantity} @ ${this.$formatters.dollars(product.rate, true, true, this.currency)}/ea</span>`
					}

					return result;
				}else{
					return '';
				}
			},

			dueDateFormattedInput: function () {
				if (this.deliverable.dueDate !== null) {
					return DateTime.fromISO(this.deliverable.dueDate).toLocaleString(DateTime.DATE_MED);
				} else {
					return null;
				}
			},

			dueDateFormatted: function () {
				// console.log(this.item);
				if (this.deliverable.dueDate !== null) {
					return DateTime.fromISO(this.deliverable.dueDate).toLocaleString(DateTime.DATE_MED);
				} else if (
					this.deliverable.dueDateRelative &&
					this.deliverable.dueDateRelative.duration &&
					this.deliverable.dueDateRelative.timeUnit
				) {
					let unit = this.deliverable.dueDateRelative.timeUnit.toLowerCase();
					return this.$tc('global.' + unit, this.deliverable.dueDateRelative.duration);
				} else {
					return '';
				}
			},



			deliverableLabel: function () {
				if (this.$validations.isEmpty(this.deliverable.deliverable)) {
					return '-';
				} else {
					return this.deliverable.deliverable;
				}
			},

			descriptionLabel: function () {
				if (this.$validations.isEmpty(this.deliverable.description)) {
					return '';
				} else {
					return this.deliverable.description;
				}
			},

			humanUnits() {
				if (!this.deliverable.dueDateRelative) return 'Units';
				let i = this.timeUnits.findIndex((u) => {
					return u.value === this.deliverable.dueDateRelative.timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return 'Units';
			},

			taxEnabled: function () {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},
		},
	};
</script>

<style lang="scss">
	.proposal-deliverable-wrapper {
		.no-margin ::v-deep {
			.v-input--selection-controls__input {
				margin-right: 0px !important;
			}
		}

		.v-input--selection-controls {
			margin-top: 0px;
			margin-right: 0px;
		}

		.deliverable {
			display: flex;
			align-items: center;
			padding: 8px 0px;

			&:first-child {
				.v-icon:hover {
					color: var(--v-blue_50-base);
				}
			}
		}

		.baseDeliverableWrapper {
			border-bottom: 1px solid var(--v-gray_50-base);
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			font-weight: 300;
			font-size: 16px;
		}

		.editableDeliverable {
			&:hover {
				background-color: var(--v-gray_10-base);
				color: var(--v-gray_50-base);
				cursor: pointer;
			}
		}

		.row.deliverableLabel {
			word-wrap: break-word;
			overflow-wrap: break-word;
		}
	}
	#proposal-deliverable-modal {
		width: 500px;
		background-color: var(--v-white-base);
		.label-style {
			font-size: 12px;
			color: var(--v-gray_80-base);
			text-align: left;
			margin-top: 12px;
		}
		.product-button {
			input {
				cursor: pointer;
			}
			.v-input__icon.v-input__icon--append .v-icon svg {
				height: 16px;
			}
		}
		.half > div {
			flex: 0 1 50%;
			gap: 12px;
		}
		.date-type-switch.v-input {
			.v-input__control .v-label {
				font-size: 12px;
				font-weight: 500;
				color: var(--v-gray_80-base);
			}
			&.v-input--is-label-active {
				.v-input__control .v-label {
					color: var(--v-gray_90-base);
				}
			}
		}

		.clear-date-icon {
			//margin-top: -11px;
			//border: 1px solid red;
			margin-right: 12px;
		}
		.due-date-icon {
			//margin-top: -2px;
			//border: 1px solid red;
			border-left: 1px solid var(--v-gray_50-base);
			//height: 28px;
			width: 40px;
			padding-right: 4px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.disabled {
				opacity: 0.6;
			}
		}
	}
	#delete-deliverable-modal {
		width: 300px;
		background-color: var(--v-white-base);
	}
</style>
