import store from '@/store';
import moment from 'moment';
import DateTime from "@/modules/utils/HDateTime";

class formatters {
	static titleCase(string) {
		var sentence = string.toLowerCase().split(' ');
		for (var i = 0; i < sentence.length; i++) {
			sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
		}
		return sentence.join(' ');
	}

	static yesNoFromBoolean(val) {
		return val ? 'Yes' : 'No';
	}

	static booleanFromYesNo(val) {
		return val === 'Yes' ? true : false;
	}

	static dollars(amount = 0, cents = true, currencySign = true, currency = store.state.defaultCurrency, maximumFractionDigits = 2, minimumFractionDigits=2) {
		try {
			if (!currency) {
				currency = store.state.defaultCurrency;
			}

			if (!amount) {
				amount = 0;
			}

			amount = amount.toString().replace(/[^-0-9.]/g, '');
			if (isNaN(amount)) amount = 0;
			if (!cents) {
				maximumFractionDigits = 0;
			}

			if(minimumFractionDigits === 0 && amount % 1 === 0){
				minimumFractionDigits = 0;
				maximumFractionDigits = 0;
			}

			if (currencySign) {
				return new Intl.NumberFormat(store.state.locale, {
					style: 'currency',
					currency: currency,
					currencySign: 'accounting',
					maximumFractionDigits: maximumFractionDigits,
					minimumFactionDigits: minimumFractionDigits
				}).format(amount);
			} else {
				return new Intl.NumberFormat(store.state.locale, {
					style: 'currency',
					currency: currency,
					maximumFractionDigits: maximumFractionDigits,
					minimumFactionDigits: minimumFractionDigits
				}).format(amount).replace(/[^0-9.,]/g, '');
			}
		}catch(err){
			console.log('Error formatting currency',err);
			return amount;
		}
	}

	static decimal(amount = 0, minimumFractionDigits = 2, maximumFractionDigits = 2, thousandsSeparator = true) {
		if(!amount){
			amount = 0;
		}
		amount = amount.toString().replace(/[^-0-9.]/g, '');
		if (isNaN(amount)) amount = 0;
		// const hasDecimals = amount - Math.floor(amount) !== 0;
		return new Intl.NumberFormat(store.state.locale, {
			style: 'decimal',
			minimumFractionDigits: minimumFractionDigits,
			maximumFractionDigits: maximumFractionDigits,
		})
			.format(amount)
			.replaceAll(thousandsSeparator ? '' : ',', '');
	}

	static currencySymbol(currency = store.state.defaultCurrency){
		try {
			if (!currency) {
				currency = store.state.defaultCurrency;
			}
			return (0).toLocaleString(
				store.state.locale, {
					style: 'currency',
					currency: currency,
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}
			).replace(/\d/g, '').trim()
		}catch(err){
			console.log('Error processing currency symbol',err);
			return '?'
		}
	}

	static snakeToCamel(str) {
		return str
			.replace(/-+/g, '-')
			.replace(/_+/g, '_')
			.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
	}

	static camelToSnake(str) {
		return str
			.split(/(?=[A-Z])/)
			.join('-')
			.toLowerCase();
	}

	static stripHtml(strWithHtml) {
		return strWithHtml.replace(/(<([^>]+)>)/gi, '');
	}

	static phoneNumber(phoneNumberString) {
		let cleaned = ('' + phoneNumberString).replace(/[^-0-9 .()+]/g, '');
		let match = cleaned.match(/^(\+?1|)?[- .(]*([2-9]\d{2})[- .)]*(\d{3})[- .]*(\d{4})$/);
		if (match) {
			const countryCode = match[1] ? '+1 ' : '';
			const ext = this.getPhoneExt(phoneNumberString, countryCode);
			return [countryCode, '(', match[2], ') ', match[3], '-', match[4], ext].join('');
		}
		return phoneNumberString;
	}
	//* This is used by phoneNumber function above
	//* Not intended to be used directly, but hey, it's your funeral
	static getPhoneExt(phone, countryCode) {
		let breakOn = 10;
		if (countryCode) breakOn = 11;
		const chars = phone.split('');
		let digits = 0;
		let i;
		for (i = 0; i < chars.length; i++) {
			if (chars[i].match(/\d/g)) digits++;
			if (digits == breakOn) break;
		}
		return chars.slice(i + 1).join('');
	}

	static formatTime(number, type) {
		if (number < 0) {
			number = number * -1;
			return number + ' ' + type + (number > 1 ? 's' : '') + ' ago';
		} else {
			return number + ' ' + type + (number > 1 ? 's' : '') + ' remaining';
		}
	}

	static formatTimeAgo (time) {
		let date = DateTime.fromISO(time);
		let now = DateTime.now();

		if (date.startOf('day').ts === now.startOf('day').ts) {
			return date.toLocaleString(DateTime.TIME_SIMPLE);
		} else if (date.startOf('week').ts == now.startOf('week').ts) {
			return date.toFormat('ccc') + ' ' + date.toLocaleString(DateTime.TIME_SIMPLE);
		} else if (date.startOf('year').ts == now.startOf('year').ts) {
			return date.toLocaleString({
				month: 'short',
				day: 'numeric',
			});
		} else {
			return date.toLocaleString(DateTime.DATE_SHORT);
		}
	}

	static formatForTimeAgo(date) {
		let yesterday = DateTime.now().minus({days:1}).startOf('day');
		let today = DateTime.now().startOf('day');
		let tomorrow = DateTime.now().plus({days:1}).startOf('day');
		let compareDate = DateTime.fromISO(date).startOf('day');

		if(compareDate.equals(today)){
			return 'Today at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
		}else if(compareDate.equals(tomorrow)){
			return 'Tomorrow at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
		}else if(compareDate.equals(yesterday)){
			return 'Yesterday at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
		}else{
			return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
		}
	}

	static formatForDaysAgo(date) {
		let yesterday = DateTime.now().minus({days:1}).startOf('day');
		let today = DateTime.now().startOf('day');
		let tomorrow = DateTime.now().plus({days:1}).startOf('day');
		let compareDate = DateTime.fromISO(date).startOf('day');

		if(compareDate.equals(today)){
			return 'Today'
		}else if(compareDate.equals(tomorrow)){
			return 'Tomorrow'
		}else if(compareDate.equals(yesterday)){
			return 'Yesterday'
		}else{
			return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
		}
	}

	static formatTimeFromNow(date) {
		if (!date || date === '') {
			return '';
		}

		let now = moment(new Date().toISOString().split('T')[0]);
		let due = moment(date);
		let days = due.diff(now, 'days');
		let weeks = due.diff(now, 'weeks');
		let months = due.diff(now, 'months');

		if (months > 0) {
			return formatters.formatTime(months, 'Month');
		} else if (weeks > 0) {
			return formatters.formatTime(weeks, 'Week');
		} else {
			return formatters.formatTime(days, 'Day');
		}
	}

	static formatSeconds(seconds) {
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds - hours * 3600) / 60);

		if (hours < 10) {
			hours = '0' + hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}

		let time = hours + ':' + minutes;
		return time;
	}

	static formatSecondsFull(seconds) {
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds - hours * 3600) / 60);
		seconds = seconds - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = '0' + hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		let time = hours + ':' + minutes + ':' + seconds;
		return time;
	}

	static formatSecondsLong(seconds) {
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds - hours * 3600) / 60);
		seconds = seconds - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = '0' + hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		//let time = hours + ':' + minutes + ':' + seconds;
		if(hours > 0){
			return hours + ' hrs ' + minutes + ' min';
		}else{
			return minutes + ' min';
		}
	}

	static formatSecondsToHours(seconds) {
		return Math.round(seconds / 60 / 60) + 'hr';
	}

	static groupBy (array, key) {
		return array.reduce((result, currentValue) => {
			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
			return result;
		}, {});
	}

	static formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024; // or use 1000 if you prefer decimal
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

		// Determine the index for the sizes array.
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		// Calculate the converted value and format it with the specified decimals.
		const converted = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

		return `${converted} ${sizes[i]}`;
	}
}

export default {
	install(Vue) {
		Vue.filter('formatForTimeAgo', formatters.formatForTimeAgo);
		Vue.filter('formatTimeFromNow', formatters.formatTimeFromNow);

		Object.defineProperties(Vue.prototype, {
			$formatters: {
				get() {
					return formatters;
				},
			},
		});
	},
};
