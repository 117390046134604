<template>
	<files ref="File" v-if="domainRoot" :show-deleted="showDeleted" :folder-id="domainRoot.id" :read-only="readOnly"></files>
</template>

<script>
	import FileSystemService from '@/modules/files/FileSystemService';
	import Files from '@/modules/files/Files';

	export default {
		name: 'DomainRoot',

		props: {
      showDeleted: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: true,
      },
			type: {
				type: String,
				default: null,
			},
			id: {
				type: String,
				default: null,
			},
		},

		components: { Files },

		data: function() {
			return {
				domainRoot: null,
				fileSystemService: new FileSystemService(),
			};
		},

		mounted() {
			this.getDomainRoot();
		},

		beforeDestroy() {},

		methods: {
			addNew: function(){
				this.$refs.File.addNew();
			},

			getDomainRoot: function() {
				this.fileSystemService.domainRoot(this.type, this.id).then((res) => {
					this.domainRoot = res.data;
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
