<template>
	<div style="margin-top:16px; margin-bottom: 16px" @click.stop>
		<input type="file" ref="fileInput" multiple style="display: none;" @change="handleFiles" />
    <v-row v-if="filteredItems.length === 0">
      <v-col cols="12">No uploaded file attachments.</v-col>
    </v-row>
    <template v-for="(item) in filteredItems">
			<v-row @contextmenu.stop.prevent="showContextMenu($event, item)" :key="'main' + item.id" class="pb-1" style="border-radius: 4px">
				<v-col cols="7" class="" :style="`padding-left: ${depth * 16}px`">
					<div
						class="row-format align-center gap-1"
						@dragover="handleDragOver"
						@drop="handleDrop"
						@dragstart="handleDragStart"
						@dragenter="handleDragEnter"
						@dragleave="handleDragLeave"
						draggable="true"
						:data-drop-enabled="readOnly ? 'false' : 'true'"
						:data-asset-type="item.assetType"
						:data-asset-id="item.id"
						:id="item.id"
					>
						<div
							v-if="item.assetType === 'Folder'"
							@click.stop="toggle(item)"
							class="row-format align-center pointer"
						>
							<v-icon v-if="isExpanded(item)" class="material-symbols-rounded" size="20"
								>keyboard_arrow_down</v-icon
							>
							<v-icon v-else class="material-symbols-rounded" size="20">chevron_right</v-icon>
						</div>
						<div v-else style="display: inline-block; width: 20px;"></div>
						<div v-if="item.assetType === 'Folder'" @click.stop="toggle(item)">
							<v-icon
								class="material-symbols-rounded pointer"
								:color="item.isDeleted || parentDeleted ? 'gray_40' : ''"
								size="20"
								>{{ isExpanded(item) ? 'folder_open' : 'folder' }}</v-icon
							>
						</div>
						<img
							v-if="item.assetType === 'File'"
							:src="item.fileIconUrl"
							alt="icon"
							draggable="false"
							style="max-width: 20px; max-height: 20px"
							:style="item.isDeleted || parentDeleted ? 'opacity: 0.4' : ''"
						/>
						<div class="row-format align-center" style="max-width: 100%">
							<div
								style="cursor: text;"
								:contenteditable="item.isDeleted || parentDeleted || isDragging || readOnly ? 'false' : 'true'"
								:class="`font-14 text-left ${item.isDeleted || parentDeleted ? 'deleted-item' : ''}`"
								@mousedown="handleMouseDown"
								@input="handleInput($event, item)"
								@keydown="preventLineBreak($event)"
								@blur="saveName(item)"
								v-tippy :content="item.notes"
							>
								{{ item.name }}
							</div>
							<!--v-icon v-if="item.shareInPortal"  size="16" class="ml-2" color="gray_40"
								>contacts</v-icon
							-->
						</div>
					</div>
				</v-col>
				<v-col
					cols="2"
					class="font-14 text-left nowrap truncate"
					v-tippy
					:content="DateTime.fromISO(item.updated).toLocaleString(DateTime.DATETIME_SHORT)"
					>{{ $formatters.formatForTimeAgo(item.updated) }}</v-col
				>
				<v-col
					cols="2"
					class="font-14 text-left nowrap truncate"
					v-tippy
					:content="item.size ? $formatters.formatBytes(item.size) : null"
					>{{ item.size ? $formatters.formatBytes(item.size) : '--' }}</v-col
				>
				<v-col cols="1" class="font-14 text-left nowrap truncate">{{
					item.versions && item.versions.length ? item.versions.length : '--'
				}}</v-col>
			</v-row>
			<file-system
				:key="'children' + item.id"
				:ref="'children-' + item.id"
				v-if="item.assetType === 'Folder' && isExpanded(item)"
				:parent-folder-id="item.id"
				:show-deleted="showDeleted"
				:parent-deleted="item.isDeleted || parentDeleted"
				:depth="depth + 1"
				:is-dragging="isDragging"
				:sort-by="sortBy"
				:sort-dir="sortDir"
			></file-system>
		</template>
		<div
			v-if="depth === 0"
			class="py-3"
			@dragover="handleDragOver"
			@drop="handleDrop"
			@dragstart="handleDragStart"
			@dragenter="handleDragEnter"
			@dragleave="handleDragLeave"
			@click.stop
			@contextmenu.stop.prevent="showContextMenu($event, null)"
			data-drop-enabled="true"
			data-asset-type="Folder"
			:data-asset-id="parentFolderId"
		></div>

		<v-menu v-model="contextMenu.visible" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute offset-y>
			<div class="context-menu">
				<div
					class="context-menu-item"
					@click="openFile"
					v-if="contextActionItem && contextActionItem.assetType === 'File' && isImageType"
				>
					Open
				</div>
				<div
					class="context-menu-item"
					@click="downloadFile"
					v-if="contextActionItem && contextActionItem.assetType === 'File'"
				>
					Download
				</div>
				<div
					v-if="contextActionItem && contextActionItem.assetType === 'File'"
					style="border-top: 1px solid var(--v-gray_30-base)"
				></div>
				<div
					class="context-menu-item"
					@click="handleUploadFile"
					v-if="!readOnly && (!contextActionItem || !contextActionItem.isDeleted)"
				>
					Upload file
				</div>
				<div class="context-menu-item" @click="handleNewFolder" v-if="!readOnly && (!contextActionItem || !contextActionItem.isDeleted)">
					New folder
				</div>
				<div style="border-top: 1px solid var(--v-gray_30-base)"></div>
				<div class="context-menu-item" @click="unDeleteItem" v-if="!readOnly && (contextActionItem && contextActionItem.isDeleted)">
					Undelete
				</div>
				<div class="context-menu-item" @click="deleteItem" v-else-if="!readOnly && contextActionItem">Delete</div>
				<div class="context-menu-item" @click="toggleShowDeleted" v-if="!readOnly">
					{{ showDeleted ? 'Hide deleted' : 'Show deleted' }}
				</div>
			</div>
		</v-menu>
		<light-box v-if="lightBoxUrl" :url="lightBoxUrl" :file-name="lightBoxFileName" @close="closeLightBox()"></light-box>
	</div>
</template>

<script>
	import FileSystemService from '@/modules/files/FileSystemService';
	import ConfirmModal from '@/components/ConfirmModal';
	import { DateTime } from 'luxon';
	import axios from 'axios';
	import LightBox from '@/modules/files/LightBox';

	export default {
		name: 'FileSystem',

		props: {
			parentFolderId: {
				type: String,
				default: null,
			},
      showDeleted: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
			parentDeleted: {
				type: Boolean,
				default: false,
			},
			isDragging: {
				type: Boolean,
				default: false,
			},
			depth: {
				type: Number,
				default: 0,
			},
			sortBy: {
				type: String,
				default: 'name'
			},
			sortDir: {
				type: String,
				default: 'desc',
			},
		},

		// Recursive component registration
		components: {
			LightBox,
			FileSystem: () => import('./FileSystem.vue'),
		},

		data() {
			return {
				DateTime: DateTime,
				fileSystemService: new FileSystemService(),
				items: [],
				isLoading: false,
				// Tracks expanded folders in the nested view (using item.id as key)
				expandedFolders: {},
				contextActionItem: null,
				uploadFileFolderId: null,
				contextMenu: {
					visible: false,
					x: 0,
					y: 0,
					// folder: null, // If needed later to track right-clicked folder
				},
				lightBoxUrl: null,
				lightBoxFileName: null,
			};
		},

		mounted() {
			this.fetchFolderContents();
			// Hide context menu on any click outside it
			document.addEventListener('click', this.hideContextMenu);
			this.$store.state.eventBus.$on('file-system-move-event', this.handleMoveEvent);
		},

		beforeDestroy() {
			document.removeEventListener('click', this.hideContextMenu);
			this.$store.state.eventBus.$off('file-system-move-event', this.handleMoveEvent);
		},

		methods: {
			toggleShowDeleted: function() {
				this.$store.state.eventBus.$emit('file-system-toggle-deleted');
				this.hideContextMenu();
			},

			closeLightBox: function() {
				this.lightBoxUrl = null;
				this.lightBoxFileName = null;
			},

			openFile: function() {
				let fileAsset = this.contextActionItem;
				this.fileSystemService
					.getSignedUrl(fileAsset.id, false)
					.then((res) => {
						console.log(res.data);
						this.lightBoxUrl = res.data;
						this.lightBoxFileName = fileAsset.name;
					})
					.finally(() => this.hideContextMenu());
			},

			downloadFile: function() {
				let fileAsset = this.contextActionItem;
				this.fileSystemService
					.getSignedUrl(fileAsset.id, true)
					.then((res) => {
						let signedUrl = res.data;

						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', fileAsset.name);
							document.body.appendChild(link);
							link.click();
						});
					})
					.finally(() => this.hideContextMenu());
			},

			handleMoveEvent: function(fileAsset) {
				let ix = this.items.findIndex((i) => i.id === fileAsset.id);

				if (fileAsset.parentFolderId === this.parentFolderId) {
					if (ix === -1) {
						this.items.push(fileAsset);
					}
				} else if (ix > -1) {
					this.items.splice(ix, 1);
				}
			},

			fetchFolderContents() {
				this.isLoading = true;
				this.fileSystemService
					.list(this.parentFolderId)
					.then((res) => {
						this.items.splice(0);
						this.items.push(...res.data);
					})
					.finally(() => (this.isLoading = false));
			},

      sortItems(itemArray) {
        itemArray.sort((a, b) => {
          let valA = a[this.sortBy];
          let valB = b[this.sortBy];

          // Handle null/undefined values
          if (valA == null && valB == null) return 0;
          if (valA == null) return this.sortDir === 'asc' ? -1 : 1;
          if (valB == null) return this.sortDir === 'asc' ? 1 : -1;

          // If both are strings, convert to lower case for case-insensitive comparison.
          if (typeof valA === 'string' && typeof valB === 'string') {
            valA = valA.toLowerCase();
            valB = valB.toLowerCase();
          }

          if (valA < valB) return this.sortDir === 'asc' ? -1 : 1;
          if (valA > valB) return this.sortDir === 'asc' ? 1 : -1;
          return 0;
        });
      },

			handleMouseDown(event) {
				if (event.button === 2) {
					event.preventDefault();
				}
			},

			handleInput(event, item) {
				item.temporaryName = event.target.innerText;
			},

			preventLineBreak(e) {
				const forbiddenKeys = ['Enter', 'Tab'];
				if (forbiddenKeys.includes(e.key)) {
					e.preventDefault();
				}
			},

			// Helper function to check if drag is enabled for this folder
			isDropEnabled(event) {
				return event.currentTarget.dataset.dropEnabled === 'true';
			},

			handleDragStart(e) {
				console.log('starting to drag ' + e.target.id);
				e.dataTransfer.setData('text/plain', e.target.id);
			},

			handleDragEnter(e) {
				if (!this.isDropEnabled(e)) return;
				e.preventDefault();
				e.currentTarget.classList.add('highlight');
			},

			handleDragLeave(e) {
				if (!this.isDropEnabled(e)) return;
				e.currentTarget.classList.remove('highlight');
			},

			handleDragOver(e) {
				if (!this.isDropEnabled(e)) return;
				e.preventDefault();
				e.dataTransfer.dropEffect = 'copy'; // Indicate that files will be copied
			},

			handleDrop(e) {
				if (!this.isDropEnabled(e)) return;
				e.preventDefault();
				e.currentTarget.classList.remove('highlight');
				let sourceId = e.dataTransfer.getData('text/plain');

				if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
					let uploadFolderId = e.currentTarget.dataset.assetId;
					this.uploadFiles(uploadFolderId, e.dataTransfer.files);
				} else if (sourceId && e.currentTarget.dataset.assetType === 'Folder') {
					let targetId = e.currentTarget.dataset.assetId;

					if (targetId !== sourceId) {
						this.fileSystemService.move(sourceId, targetId).then((res) => {
							this.$store.state.eventBus.$emit('file-system-move-event', res.data);
						});
					}
				}else if(sourceId && e.currentTarget.dataset.assetType === 'File'){
					let targetId = this.parentFolderId;
					if (targetId !== sourceId) {
						this.fileSystemService.move(sourceId, targetId).then((res) => {
							this.$store.state.eventBus.$emit('file-system-move-event', res.data);
						});
					}
				}
			},

			handleUploadFile() {
				this.uploadFileFolderId =
					this.contextActionItem && this.contextActionItem.assetType === 'Folder'
						? this.contextActionItem.id
						: this.parentFolderId;
				this.hideContextMenu();
				this.$refs.fileInput.click();
			},

			handleFiles(event) {
				let files = event.target.files;
				let uploadFileFolderId = this.uploadFileFolderId;
				this.uploadFiles(uploadFileFolderId, files);
				this.$refs.fileInput.value = '';
			},

			async uploadFiles(uploadFileFolderId, files) {
				let ourFiles = [];

        for(let i=0; i < files.length; i++){
          ourFiles.push(files[i]);
        }

				this.$store.commit('startLoading');
				try {
					let uploadedFiles = [];
					for (let i = 0; i < ourFiles.length; i++) {
						let result = await this.fileSystemService.upload(uploadFileFolderId, ourFiles[i]);
						uploadedFiles.push(result.data);
					}

					if (uploadFileFolderId === this.parentFolderId) {
						uploadedFiles.forEach((u) => {
							let ix = this.items.findIndex((i) => i.id === u.id);
							if (ix > -1) {
								this.items.splice(ix, 1, u);
							} else {
								this.items.push(u);
							}
						});
					} else {
						let item = this.items.find((i) => i.id === uploadFileFolderId);
						if (this.isExpanded(item)) {
							uploadedFiles.forEach((f) => this.$refs['children-' + item.id][0].addItem(f));
						} else {
							this.toggle(item);
						}
					}
				} catch (err) {
					console.log(err);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			saveName(item) {
				if (!item.temporaryName) {
					return;
				}
				this.fileSystemService
					.rename(item.id, item.temporaryName)
					.then((res) => {
						let ix = this.items.findIndex((i) => i.id === item.id);
						if (ix > -1) {
							this.items.splice(ix, 1, res.data);
						}
					})
					.catch((err) => {
						console.error('Failed to save name', err);
					});
			},

			addFolder(parentId) {
				this.fileSystemService
					.createDirectory(parentId, 'New Folder')
					.then((res) => {
						if (parentId === this.parentFolderId) {
							this.items.push(res.data);
						} else {
							let item = this.items.find((i) => i.id === parentId);
							if (this.isExpanded(item)) {
								this.$refs['children-' + item.id][0].addItem(res.data);
							} else {
								this.toggle(item);
							}
						}
					})
					.finally(() => this.hideContextMenu());
			},

			unDeleteItem() {
				let item = this.contextActionItem;
				this.fileSystemService
					.unDelete(item.id)
					.then((res) => {
						let ix = this.items.findIndex((i) => i.id === item.id);
						if (ix > -1) {
							this.items.splice(ix, 1, res.data);
						}
					})
					.finally(() => this.hideContextMenu());
			},

			deleteItem() {
				let item = this.contextActionItem;
				this.hideContextMenu();

				let binding = {
					headingText: 'Confirm',
					bodyText: null,
				};

				if (item.assetType === 'Folder') {
					binding.bodyText = `Are you sure you want to delete the folder <strong>${item.name}</strong> and all of it's contents?`;
				} else {
					binding.bodyText = `Are you sure you want to delete the file <strong>${item.name}</strong>?`;
				}

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.fileSystemService.delete(item.id).then((res) => {
							let ix = this.items.findIndex((i) => i.id === item.id);
							if (ix > -1) {
								this.items.splice(ix, 1, res.data);
							}
						});
					}
				});
			},

			addItem(item) {
				let ix = this.items.findIndex((i) => i.id === item.id);
				if (ix > -1) {
					this.items.splice(ix, 1, item);
				} else {
					this.items.push(item);
				}
			},

			toggle(item) {
				if (item.assetType !== 'Folder') return;
				this.$set(this.expandedFolders, item.id, !this.expandedFolders[item.id]);
			},

			isExpanded(item) {
				return !!this.expandedFolders[item.id];
			},

			// Show the context menu at the click position
			showContextMenu(event, item) {
				if (this.parentDeleted) {
					return;
				}
				this.contextMenu.x = event.clientX;
				this.contextMenu.y = event.clientY;
				this.contextMenu.visible = true;
				this.contextActionItem = item;
			},

			// Hide the context menu
			hideContextMenu() {
				this.contextMenu.visible = false;
				this.contextActionItem = null;
			},

			// Handle clicking on "New Folder"
			handleNewFolder() {
				let parentId = this.parentFolderId;
				if (this.contextActionItem?.assetType === 'Folder') {
					parentId = this.contextActionItem.id;
				}
				this.addFolder(parentId);
				this.hideContextMenu();
			},
		},

		computed: {
			isImageType: function() {
				let fileAsset = this.contextActionItem;
				if (fileAsset && fileAsset.assetType === 'File') {
					let name = fileAsset.name.toUpperCase();
					if (
						name.endsWith('JPG') ||
						name.endsWith('PNG') ||
						name.endsWith('GIF') ||
						name.endsWith('PDF') ||
						name.endsWith('SVG')
					) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},

			filteredItems: function() {
				let items = [];
				if (this.showDeleted) {
					items.push(... this.items);
				} else {
					items.push(... this.items.filter((i) => !i.isDeleted));
				}
				this.sortItems(items);
				return items;
			},
		},
	};
</script>

<style scoped lang="scss">
	div[contenteditable='true']:focus {
		outline: 1px solid var(--v-gray_30-base);
		background-color: var(--v-white-base);
		padding-right: 4px;
		padding-left: 4px;
	}

	.deleted-item {
		color: var(--v-gray_40-base);
		text-decoration: line-through;
	}
	/* Highlight style */
	.highlight {
		background-color: var(--v-gray_30-base);
		color: var(--v-white-base);
		.v-icon {
			color: var(--v-white-base) !important;
		}
	}
	/* Context Menu Styling */
	.context-menu {
		//position: fixed;
		background: var(--v-white-base);
		//border: 1px solid var(--v-gray_30-base);
		//z-index: 1000;
		width: 150px;
		//box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		text-align: left;
	}
	.context-menu-item {
		font-size: 14px;
		padding: 4px 8px;
		cursor: pointer;
	}
	.context-menu-item:hover {
		background-color: #f0f0f0;
	}
</style>
