<template>
	<div v-if="deliverables.length">
		<div class="brand-medium pointer" @click="$router.push('/projects?v=overview')">
			{{ $t('welcome.currently-have') }} <span style="color: var(--v-primary-base)">{{ deliverables.length }}</span>
			{{ $tc('welcome.approval', deliverables.length) }}.
		</div>
		<div class="column-format" style="gap: 8px; margin-top: 12px">
			<div
				v-for="deliverable in deliverables"
				:key="deliverable.id"
				class="action-button"
				@click.stop="openDeliverable(deliverable)"
			>
				{{ deliverable.name }}
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';

	export default {
		name: 'Deliverables',

		props: ['deliverables'],

		components: {},

		data: function() {
			return {
				projectService: new ProjectService(),
				projectTypes: [],
				projects: [],
			};
		},

		mounted() {
			this.getProjectTypes();
			this.getProjectList();
		},

		beforeDestroy() {},

		methods: {
			getProjectList: function() {
				this.projectService.getProjects().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
				});
			},

      getProjectTypes: function() {
				this.projectService.getProjectTypes().then((res) => {
					this.projectTypes.push(...res.data);
				});
			},

      getProjectType: function(projectTypeId){
        return this.projectTypes.find(p => p.id === projectTypeId);
      },

			openDeliverable: function(deliverable) {
				let binding = {
					origDeliverable: deliverable,
					project: this.projects.find((p) => p.id === deliverable.projectId),
					statusList: this.getProjectType(deliverable.projectTypeId).statusList,
				};

				this.$store.state.globalModalController.openModal(DeliverableDetail, binding, true, false).then((res) => {
					if (res && !res.approvalRequired) {
						let ix = this.deliverables.findIndex((d) => d.id === res.id);
						if (ix > -1) {
							this.deliverables.splice(ix, 1);
						}
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
