<template>
	<v-row justify="center" align="center">
		<div class="text-center ma-12">
			<v-progress-circular :indeterminate="true" size="200" color="light-blue"></v-progress-circular>
		</div>
	</v-row>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import ClientService from '@/modules/client/ClientService';

	export default {
		name: 'Loader',

		mounted() {
			AuthService.refreshToken()
				.then((res) => {
					this.handleLoginSuccess(res);
				})
				.catch((err) => {
					console.log('Failed to fetch refresh token:' + err);
					this.handleLoginFailure();
				});
		},

		data() {
			return {
				clientService: new ClientService(),
			};
		},

		methods: {
			handleLoginSuccess(result) {
				console.log('handling login results');

				this.$store.commit('setInitialized', true);
				this.$store.commit('setAccessToken', result);

				AuthService.getProfile()
					.then((res) => {
						this.$store.commit('setLoggedInUser', res);
						this.getContactRecord();
						this.getDefaultCurrency();

						let clientId = this.$store.getters.getClientId;
						if (!clientId) {
							return;
						}
						AuthService.getCustomPages(clientId).then((res) => {
							this.$store.state.customPages.splice(0);
							this.$store.state.customPages.push(...res);
							this.$store.state.customPages.forEach((p) => {
								if (p.isDefault) {
									p.sortOrder = 1;
								}
								p.slug = p.title.toLowerCase().replace(/[\W_]+/g, '-');
							});
						});

						this.setRefresh();
						this.$router.push(this.$store.state.origRequestedUri);
					})
					.catch((err) => {
						console.log('Something went wrong fetching the user profile.' + err);
						this.$store.commit('logOut');
						this.$store.commit('setInitialized', true);
						this.$router.push('/login').catch((err) => console.log(err));
					});

				AuthService.getCustomEmbed().then((res) => {
					this.$store.state.customEmbed = res.embed;
				});
			},

			getContactRecord() {
				new ClientService().getContact().then((res) => {
					this.$store.commit('setContactRecord', res.data);
				});
			},

			getDefaultCurrency() {
				new ClientService().getDefaultCurrency().then((res) => {
					this.$store.state.defaultCurrency = res.data;
				});
			},

			setRefresh() {
				setInterval(() => this.clientService.getDefaultCurrency(), 60000);
			},

			handleLoginFailure() {
				this.$store.commit('setInitialized', true);
				this.$router.push('/login');
			},
		},
	};
</script>

<style scoped></style>
