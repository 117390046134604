<template>
<div
    @click="handleClick"
    :class="`${route ? 'pointer' : ''} row-format ${expanded ? 'align-center' : 'align-center'} mx-2 ${active ? 'nav-link-active' : ''} nav-link${
			expanded ? '-expanded' : ''
		}`"
>
<div v-if="expanded" class="row-format align-center" style="padding-left: 10px;">
  <v-icon :class="!active ? 'material-symbols-outlined' : 'material-symbols-outlined'" :color="active ? 'primary' : 'custom'">{{icon}}</v-icon>
  <div :class="`ml-2 nav-label ${active ? 'active' : ''}`">{{ label }}</div>
</div>
<div v-else class="row-format centered"  style="padding-left: 10px;" :content="label" v-tippy="{placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }">
  <v-icon :class="!active ? 'material-symbols-outlined' : 'material-symbols-outlined'" :color="active ? 'primary' : 'custom'">{{icon}}</v-icon>
</div>
</div>
</template>

<script>
//import HIcon3 from '@/components/HIcon3';
export default {
  name: 'NavLink',

  props: ['label', 'icon', 'route', 'disableActive','altRoutes','hyperLink'],

  components: {  },

  data: function () {
    return {};
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    handleClick: function () {
      if(this.hyperLink) {
        window.open(this.hyperLink,'_blank');
      }else{
        this.$router.push(this.route);
      }
    },
  },

  computed: {
    expanded: function () {
      return this.$store.state.leftNavExpanded;
    },
    active: function () {
      if (this.disableActive) {
        return false;
      }
      let active =  this.$route.path.startsWith(this.route.split("?")[0]);
      if(active){
        return true;
      }

      if(this.altRoutes){
        for(let i=0; i < this.altRoutes.length; i++){
          if(this.$route.path.startsWith(this.altRoutes[i])){
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.nav-label {
  font-size: 14px;
  line-height: 14px;
  color: var(--v-custom-base);
  font-weight: 500;
  text-align: left;
}

.filled {
  font-variation-settings:
      'FILL' 100,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48
}

.active {
  color: var(--v-primary-base) !important;
  background-color: var(--v-white-base);
  border: none;
}

.nav-link-active {
  background-color: var(--v-white-base);
}

.nav-link {
  width: 44px;
  height: 44px;
  border-radius: 8px;
}

.nav-link-expanded {
  width: 150px;
  height: 44px;
  border-radius: 8px;
}
</style>