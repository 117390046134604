import { render, staticRenderFns } from "./ServiceItemRenderer.vue?vue&type=template&id=67420a15&scoped=true"
import script from "./ServiceItemRenderer.vue?vue&type=script&lang=js"
export * from "./ServiceItemRenderer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67420a15",
  null
  
)

export default component.exports