<template>
	<div class="column-format gap-2">
    <div class="row-format gap-1 align-center">
      <div class="brand-medium">Edit product or service</div>
      <div class="ml-auto"><v-icon class="pointer" @click="handleClose()">close</v-icon></div>
    </div>
    <editor v-if="showTinyMCE" ref="description" :init="mceConfig" rows="3" auto-grow v-model="item.description"> </editor>
		<div class="row-format align-center gap-2">
			<v-text-field
				type="number"
				dense
				hide-details
				outlined
				persistent-placeholder
				v-model="item.quantity"
				@change="qtyChanged"
				label="Quantity"
				style="background-color: var(--v-white-base)"
			></v-text-field>
			<v-text-field
				type="number"
				dense
				hide-details
				outlined
				persistent-placeholder
				:prefix="$formatters.currencySymbol(agreement.currency)"
				v-model="item.rate"
				label="Rate"
				placeholder="Included"
				style="background-color: var(--v-white-base)"
			></v-text-field>
			<v-checkbox
				dense
				hide-details
				persistent-placeholder
				:true-value="true"
				:false-value="false"
				v-model="item.taxable"
				label="Taxable"
			></v-checkbox>
		</div>

		<v-select
			hide-details
			dense
			outlined
			v-model="item.oneTimeFee"
			:items="oneTimeFeeOptions"
			v-if="servicePackage.fees.feeType === 'Retainer'"
		>
		</v-select>

		<v-switch hide-details dense class="mt-0" v-model="item.relativeDueDate" label="Relative due date"></v-switch>
		<div class="row-format gap-2 align-center">
			<v-text-field
				v-if="item.relativeDueDate"
				type="number"
				inputmode="number"
				min="0"
				placeholder="No due date"
				:label="
					$t('templates.packages.deliverable-detail-modal.duration-placeholder', {
						units: humanUnits,
					})
				"
				hide-details
				dense
				outlined
				persistent-placeholder
				v-model="item.dueDateRelative.duration"
				@keydown="handleDurationKeydown($event)"
				style="background-color: var(--v-white-base)"
			/>
			<v-select
				v-if="item.relativeDueDate"
				:label="$t('templates.packages.deliverable-detail-modal.units-placeholder')"
				hide-details
				dense
				outlined
				persistent-placeholder
				:items="timeUnits"
				item-value="value"
				item-text="text"
				v-model="item.dueDateRelative.timeUnit"
				style="background-color: var(--v-white-base)"
			/>
			<date-selector
				label="Due date"
				style="background-color: var(--v-white-base); width:100%"
				:standard="true"
				:date="item.dueDate"
				@change="item.dueDate = $event"
				v-if="!item.relativeDueDate"
			></date-selector>
		</div>
		<div class="row-format centered gap-2">
			<v-btn icon><v-icon class="material-symbols-rounded" @click="confirmDelete()">delete</v-icon></v-btn>
			<v-btn class="super-action" style="min-width: 150px" @click="save()">Save</v-btn>
		</div>
	</div>
</template>

<script>
import { v4 as uuid } from 'uuid';
	import DateSelector from '@/components/DateSelector';
	import ConfirmModal from '@/components/ConfirmModal';
import editor from '@tinymce/tinymce-vue';
	export default {
		name: 'ServiceItemEditor',

		props: ['agreement', 'serviceItem', 'servicePackage'],

		components: { DateSelector, editor },

		data: function() {
			return {
				item: { ...this.serviceItem },
				quantityUpdated: false,
				oneTimeFeeOptions: [
					{ text: 'One time / setup fee', value: true },
					{ text: `Adds to ${this.servicePackage.fees.retainerSchedule} fees`, value: false },
				],
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
			};
		},

		mounted() {
      this.$nextTick(() => {
        this.showTinyMCE = true;
      });
      this.handleNoProduct();
    },

    beforeUnmount() {
      this.showTinyMCE = false;
    },

		beforeDestroy() {},

		methods: {
      handleClose: function() {
        this.$emit('close');
      },

			save: function() {
				this.$emit('save', this.item);
				this.$emit('close');
			},

			qtyChanged: function() {
				this.quantityUpdated = true;
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			confirmDelete: function() {
				let confirm = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this item from the package?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, confirm).then((res) => {
					if (res) {
						this.$emit('delete', this.item);
					}
				});
			},

      handleNoProduct: function() {
        if (!this.item.product) {
          this.item.product = {
            description: this.item.description,
            productName: this.item.description.split(/\n|<br>/)[0].replace(/<\/?p>/g, ""),
            descriptionFormat: "HTML",
            id: uuid()
          }
        }
      }
		},

		computed: {
			showQuantity: function() {
				return this.servicePackage.fees.feeType === 'Per Item';
			},

			humanUnits() {
				if (!this.item.dueDateRelative) return 'Units';
				let i = this.timeUnits.findIndex((u) => {
					return u.value === this.item.dueDateRelative.timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return 'Units';
			},
		},
	};
</script>

<style lang="scss">
	hr {
		background-color: var(--v-gray_30-base);
		border: none;
		height: 1px;
	}
</style>
