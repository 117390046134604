import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class FileSystemService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	get(id) {
		return this.httpClient
			.get(`/${store.getters.getClientId}/files/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	list(parentFolderId = null) {
		let url = `/${store.getters.getClientId}/files`;

		if (parentFolderId) {
			url += `?parentFolderId=${encodeURIComponent(parentFolderId)}`;
		}

		return this.httpClient
			.get(url)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	domainRoot(type,id){
		return this.httpClient
			.get(`/${store.getters.getClientId}/files/domainRoot?type=${type}&id=${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	rename(id,name) {
		return this.httpClient
			.post(`/${store.getters.getClientId}/files/${id}/rename?name=${encodeURIComponent(name)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	move(id,destinationFolderId) {
		let url = `/${store.getters.getClientId}/files/${id}/move`;

		if (destinationFolderId) {
			url += `?destinationFolderId=${encodeURIComponent(destinationFolderId)}`;
		}

		return this.httpClient
			.post(url)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	delete(id){
		return this.httpClient
			.delete(`/${store.getters.getClientId}/files/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	unDelete(id){
		return this.httpClient
			.delete(`/${store.getters.getClientId}/files/${id}/cancel`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(id, download = true, version = null){
		let url = `/${store.getters.getClientId}/files/${id}/signedUrl?download=${download}`;

		if (version !== null) {
			url += `&version=${version}`;
		}

		return this.httpClient
			.get(url)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	async upload(parentFolderId, file) {
		const formData = new FormData();
		formData.append('parentFolderId', parentFolderId);
		formData.append('file', file);

		return await this.httpClient
			.post(`/${store.getters.getClientId}/files`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});
	}

	createDirectory(parentFolderId, directoryName) {
		const formData = new FormData();
		formData.append('parentFolderId', parentFolderId);
		formData.append('directoryName', directoryName);

		return this.httpClient
			.post(`/${store.getters.getClientId}/files`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
