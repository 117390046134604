import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '../views/NotFound.vue';
import Login from '@/modules/auth/Login';
import store from '../store';
import WelcomePage from '@/modules/welcome/WelcomePage';
import MagicToken from '@/modules/auth/MagicToken';
import LogOut from '@/modules/auth/LogOut';
import Loader from '@/modules/auth/Loader';
import InvoiceRoutes from '@/modules/invoices/InvoiceRoutes';
import TimeWorkedRoutes from '@/modules/timetracking/TimeWorkedRoutes';
import ProposalRoutes from '@/modules/proposal/ProposalRoutes';
import ProjectRoutes from '@/modules/projects/ProjectRoutes';
import MeetingRoutes from '@/modules/meetings/MeetingRoutes';
import FormRoutes from '@/modules/forms/FormRoutes';
import SubscriptionRoutes from '@/modules/subscriptions/SubscriptionRoutes';
import TicketRoutes from '@/modules/tickets/TicketRoutes';
import PublicRouter from '@/modules/public/PublicRouter';
import CustomPage from '@/modules/custom/CustomPage';
import DiscoveryForm from '@/modules/forms/DiscoveryForm';
import AgreementRoutes from '@/modules/agreements/AgreementRoutes';
import MainFileSystem from '@/modules/files/MainFileSystem';

Vue.use(VueRouter);

const baseRoutes = [
	{
		path: '/loader',
		name: 'loader',
		component: Loader,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/magicToken',
		name: 'MagicToken',
		component: MagicToken,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/logout',
		name: 'LogOut',
		component: LogOut,
	},
	{
		path: '/',
		redirect: '/welcome',
	},
	{
		path: '/welcome',
		name: 'Welcome',
		component: WelcomePage,
	},
	{
		path: '/files',
		name: 'Files',
		component: MainFileSystem,
	},
	{
		path: '/discovery',
		name: 'Discovery',
		component: DiscoveryForm,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/public/*',
		name: 'Public',
		component: PublicRouter,
		props: true,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/pages/:slug',
		name: 'Custom',
		component: CustomPage,
		props: true,
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
		meta: {
			insecure: true,
		},
	},
];

const routes = baseRoutes.concat(
	InvoiceRoutes,
	TimeWorkedRoutes,
	ProposalRoutes,
	ProjectRoutes,
	MeetingRoutes,
	FormRoutes,
	SubscriptionRoutes,
	TicketRoutes,
	AgreementRoutes
);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if(to.meta.insecure){
		next();
	}else if (!store.state.initialized) {
		if (to.meta.initState) {
			next();
		} else {
			store.commit('setOrigRequestedUri', to.fullPath);
			next('/loader');
		}
	} else {
		if (store.state.isLoggedIn) {
			next();
		} else {
			next('/login');
		}
	}
});

export default router;
