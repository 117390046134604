<template>
	<v-dialog
			:value="dialog"
			:max-width="maxWidth"
			:persistent="true"
			:overlay-color="overlayColor"
			:overlay-opacity="overlayOpacity"
			@click:outside="!persistent ? handleClose() : ''"
			:fullscreen="fullscreen"
	>
		<div id="dialog-div" :class="'pa-0 ma-0' + (fullscreen ? ' fullscreen' : '')">
			<v-btn id="close-x" icon rounded @click.prevent="handleClose()">
				<v-icon size="20">$close</v-icon>
			</v-btn>
			<div id="alert-div">
				<alert v-bind:alert="alert" />
			</div>
			<div class="content-div pa-2">
				<slot :setAlert="setAlert"></slot>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import Alert from './Alert';

export default {
	name: 'Modal',
	components: { Alert },

	props: {
		dialog: { type: Boolean, default: false },
		fullscreen: { type: Boolean, default: false },
		persistent: { type: Boolean, default: true },
		overlayColor: { type: String, default: 'modal_overlay' },
		overlayOpacity: { type: String, default: '0.7' },
		closeOnEscape: { type: Boolean, default: true },

		maxWidth: {
			type: Number,
			default: 600,
		},
	},

	data() {
		return {
			alert: {},
		};
	},

	mounted() {
		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.handleKeyDown);
		}
	},

	beforeDestroy() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.handleKeyDown);
		}
	},

	methods: {
		handleClose: function() {
			this.$emit('close');
		},

		handleKeyDown: function(e) {
			if (e.key === 'Escape') {
				this.handleClose();
			}
		},

		setAlert: function(alert) {
			this.alert = alert;
		},
	},

	watch: {
		dialog(newVal) {
			if (this.closeOnEscape) {
				if (newVal) {
					document.addEventListener('keydown', this.handleKeyDown);
				} else {
					document.removeEventListener('keydown', this.handleKeyDown);
				}
			}
		},
	},

	computed: {},
};
</script>

<style lang="scss" scoped>
#dialog-div {
	position: relative;
	background-color: var(--v-white-base);
	border-radius: 4px !important;
	&.fullscreen {
		border-radius: 0 !important;
		height: 100vh !important;
		display: flex;
		justify-content: center;
		align-items: center;
		.content-div {
			flex: 1 1 auto;
		}
	}
	#close-x {
		position: absolute;
		z-index: 99;
		right: 8px;
		top: 8px;
		height: 40px;
		width: 40px;
	}
	#alert-div {
		position: absolute;
		z-index: 100;
		top: 6px;
		left: 0;
		right: 0;
	}
}
</style>
