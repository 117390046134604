<template>
	<div id="proposal-deliverables">
		<v-container fluid class="pa-0 ma-0" :style="visible ? '' : 'opacity: 0.3'">
			<v-row>
				<v-col cols="12" sm="7" align="left">
					<div :style="headerStyle">
						<span v-html="lHeader" v-if="!editMode"></span>
						<editor
							ref="headerEditor"
							v-if="editMode"
							class="grey-dots"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="lHeader"
							:inline="true"
							:init="mceConfigCover"
							@input="handleHeaderInput"
						></editor>
					</div>
					<div class="font-14 font-gray_70 font-italic"  v-if="totalUpfrontAmount && lAutomations.paymentRequiredToStart">
						{{ $formatters.dollars(totalUpfrontAmount, true, true, currency) }} payment required before project start.
					</div>
				</v-col>
				<v-col cols="12" sm="5" align="right" :class="`align-start ${$vuetify.breakpoint.xsOnly ? 'my-n5' : 'justify-end'} d-flex`" style="gap: 8px">
					<template v-if="lFeeSchedule">
						<div
							@click="openFeeSchedule()"
							:class="'pr-2 column-format' + (editMode ? ' feeEditMode' : '')"
							style="text-align: right; width: fit-content"
						>
							<div :style="headerStyle" class="text-right mr-n3" v-html="feeSchedulePrimary"></div>
							<div :style="bodyStyle" class="text-right mr-n3" v-html="feeScheduleSecondary"></div>
						</div>

						<v-menu
							v-if="createPackageAllowed && editMode"
							:nudge-bottom="0"
							:elevation="0"
							content-class="add-new-menu"
							bottom
							left
							rounded
							offset-overflow
							offset-y
							transition="slide-y-transition"
						>
							<template v-slot:activator="menu">
								<v-btn v-on="menu.on" icon class="secondary-action"
									><v-icon size="20">$moreHorizontal</v-icon></v-btn
								>
							</template>
							<div class="add-new-dropdown">
								<div @click="openFeeSchedule()" class="add-new-item nowrap">
									Edit settings
								</div>
								<div @click="createPackage" class="add-new-item nowrap">
									{{ $t('templates.save-as-deliverable-package') }}
								</div>
							</div>
						</v-menu>
					</template>
				</v-col>
			</v-row>
			<v-row :class="deliverableCss" :style="bodyStyle" class="mt-2" v-if="lDeliverables.length > 0">
				<v-col cols="12">
					<v-container fluid class="pa-0 ma-0">
						<draggable v-model="lDeliverables" handle=".deliverableHandle" @end="dragEnd">
							<div v-for="(item, index) in lDeliverables" :key="item.id">
								<deliverable
									:index="index"
									:currency="currency"
									:tax-label="taxLabel"
									:tax-rate="computedTaxRate"
									:fee-schedule="feeSchedule"
									:deliverable="item"
									:edit-mode="editMode"
									:show-due-date="anyDeliverableWithDueDate"
									:show-pricing="showDeliverablePricing"
									:main-deliverable-columns="mainDeliverableColumns"
									:show-product-select="
										(feeSchedule && feeSchedule.feeType === 'Per Item') || item.initialSetupTask
									"
									@delete="deleteDeliverable(item.id)"
									@changed="notifyChange"
								></deliverable>
							</div>
						</draggable>
					</v-container>
				</v-col>
			</v-row>
			<v-row class="text-left" :style="bodyStyle">
				<v-col cols="12" v-if="editMode" class="pa-0 ma-0 gap-3 row-format">
					<v-btn text color="primary" @click="addDeliverable(false)" class="px-0">
						<v-icon size="16">$plusCircle</v-icon>
						<span class="font-14 brand-medium">{{ $t('proposal.deliverables.add') }}</span>
					</v-btn>
					<v-btn text color="primary" @click="addDeliverable(true)" class="px-0">
						<v-icon size="16">$plusCircle</v-icon>
						<span class="font-14 brand-medium">{{ $t('proposal.deliverables.add-setup') }}</span>
					</v-btn>
				</v-col>
				<input type="hidden" :value="totalUpfrontAmount" />
				<v-col cols="12" v-if="deliverablesCount > 1" align="left" class="mt-1">
					<v-btn
						:disabled="!clientMode"
						color="primary"
						:outlined="!selectedPackage"
						@click="packageSelect"
						v-if="deliverablesCount > 0 && !forceAllDeliverables"
						:class="!clientMode ? 'mt-2' : ''"
					>
						{{ selectedPackage ? $t('proposal.deliverables.selected') : $t('proposal.deliverables.select-this') }}
						<v-icon v-if="selectedPackage" x-small class="ml-2">$check</v-icon>
					</v-btn>
					<div class="mt-2" v-if="!clientMode && !isLocked">
						<div @click.stop="toggleMultipleDeliverables" text class="font-primary pointer">*{{ buttonLabel }}</div>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<basic-modal v-if="editFees" :dialog="editFees" :persistent="true" @close="editFees = false">
			<deliverable-fees
				:currency="currency"
				:fee-schedule="lFeeSchedule"
				:automations="lAutomations"
				:payment-node-count="paymentNodeCount"
				@change="handleFeeChange"
				@close="editFees = false"
			></deliverable-fees>
		</basic-modal>
	</div>
</template>

<script>
  import DeliverableFees from './DeliverableFees';
	import Deliverable from './Deliverable';
	import { v4 as uuidv4 } from 'uuid';
	import draggable from 'vuedraggable';
	import BasicModal from '@/components/BasicModal';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'Deliverables',

		props: [
			'account',
			'blockType',
			'header',
			'selectedPackage',
			'deliverables',
			'feeSchedule',
			'deliverableAutomation',
			'deliverablesCount',
			'deliverableSelected',
			'id',
			'editMode',
			'mceConfigHeader',
			'mceConfigCover',
			'headerStyle',
			'formatting',
			'clientMode',
			'isLocked',
			'currency',
			'allowMultipleDeliverables',
			'forceAllDeliverables',
			'templateMode',
			'taxRate',
			'paymentNodeCount',
		],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			Deliverable,
			DeliverableFees,
			BasicModal,
			draggable,
		},

		data: function() {
			return {
				editFees: false,
				lDeliverables: [],
				lHeader: this.header,
				// lFeeSchedule: this.initNullFeeScheduled(this.feeSchedule),
				lFeeSchedule: cloneDeep(this.feeSchedule),
				lAutomations: cloneDeep(this.deliverableAutomation),
				lSelectedPackage: this.selectedPackage,
			};
		},

		mounted() {
			this.scrubDeliverables();
		},

		methods: {
			scrubDeliverables() {
				let deliverables = [];
				for (let i = 0; i < this.deliverables.length; i++) {
					if (!this.deliverables[i]) continue;
					deliverables.push(this.deliverables[i]);
				}
				this.lDeliverables = deliverables;
				this.isReady = true;
			},

			dragEnd: function() {
				this.notifyChange();
			},

			handleFeeChange: function(event) {
				this.lFeeSchedule = event.feeSchedule;
				this.lAutomations = event.automations;
				this.notifyChange();
			},

			openFeeSchedule: function() {
				if (this.editMode) {
					this.editFees = true;
				}
			},

			packageSelect: function() {
				if (this.clientMode && !this.isLocked) {
					this.lSelectedPackage = !this.lSelectedPackage;
					this.notifyChange();
				}
			},

			notifyChange: function() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					deliverables: this.lDeliverables,
					selectedPackage: this.lSelectedPackage,
					feeSchedule: this.lFeeSchedule,
					deliverableAutomation: this.lAutomations,
					taxRate: this.computedTaxRate,
				});
				this.$nextTick(() => this.$emit('total-upfront-amount', this.calculateTotalUpfrontAmount()));
			},

			toggleMultipleDeliverables: function() {
				this.$emit('toggleMultipleDeliverables');
			},

			handleHeaderInput: function() {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			deleteDeliverable: function(id) {
				for (let i = 0; i < this.lDeliverables.length; i++) {
					if (this.lDeliverables[i].id === id) {
						this.lDeliverables.splice(i, 1);
						break;
					}
				}

				this.notifyChange();
			},

			addDeliverable: function(initialSetupTask = false) {
				let deliverable = {};
				deliverable.id = uuidv4();
				deliverable.deliverable = null;
				deliverable.description = null;
				deliverable.dueDate = null;
				deliverable.dueDateRelative = {};
				deliverable.product = null;
				deliverable.quantity = 0;
				deliverable.isNew = true;
				deliverable.initialSetupTask = initialSetupTask;

				this.lDeliverables.push(deliverable);
				this.notifyChange();
			},

			setFeeType: function(feeType) {
				this.lFeeSchedule = {
					feeType: feeType,
					amount: 0,
					retainerSchedule: 'Monthly',
					estimateMax: 0,
					estimateMin: 0,
				};
				if (feeType !== 'Per Item') {
					this.openFeeSchedule();
				}
			},

			createPackage() {
				this.$emit('create-package-from-deliverables', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					deliverables: this.lDeliverables,
					selectedPackage: this.lSelectedPackage,
					feeSchedule: this.lFeeSchedule,
				});
			},

			calculateTotalUpfrontAmount: function() {
				if (!this.lFeeSchedule || !this.lAutomations || !this.lAutomations.autoCreateProject) {
					return 0;
				}

				let amount = parseFloat(this.lFeeSchedule.amount ? this.lFeeSchedule.amount : 0);
				let usePercentage = this.lAutomations.invoicePercentage;
				let invoiceAmount = this.lAutomations.invoiceAmount;
				let invoicePercentage = invoiceAmount / 100;
				let taxPercentage = this.taxRate / 100;

				if (this.lFeeSchedule.feeType === 'Retainer') {
					let total = parseFloat(this.lFeeSchedule.amount);
					if (this.lFeeSchedule.taxable && taxPercentage) {
						total = total + total * taxPercentage;
					}
					return total + this.setupFeeTotal;
				}

				if (!usePercentage) {
					let total = invoiceAmount;
					if (this.lFeeSchedule.taxable && taxPercentage) {
						total = total + total * taxPercentage;
					}
					return total + this.setupFeeTotal;
				}

				if (this.lFeeSchedule.feeType === 'Hourly') {
					let min = parseInt(this.lFeeSchedule.estimateMin ? this.lFeeSchedule.estimateMin : 0, 10);
					let max = parseInt(this.lFeeSchedule.estimateMax ? this.lFeeSchedule.estimateMax : 0, 10);
					let hours = 0;
					if (min && max) {
						hours = (min + max) / 2;
					} else if (min) {
						hours = min;
					} else if (max) {
						hours = max;
					}

					let total = (hours * amount) * invoicePercentage;
					if (this.lFeeSchedule.taxable && taxPercentage) {
						total = total + (total * taxPercentage);
					}
					return total + this.setupFeeTotal;
				} else if (this.lFeeSchedule.feeType === 'Fixed Price') {
					let total = amount * invoicePercentage;
					if (this.lFeeSchedule.taxable && taxPercentage) {
						total = total + (total * taxPercentage);
					}
					return total + this.setupFeeTotal;
				} else if (this.lFeeSchedule.feeType === 'Per Item') {
					let total = 0;

					this.lDeliverables.forEach((d) => {
						if (!d.initialSetupTask && d.quantity && d.product && d.product.rate) {
							let subTotal = d.quantity * d.product.rate;
							if (d.product.taxable && taxPercentage) {
								subTotal = subTotal + subTotal * taxPercentage;
							}
							total = total + subTotal;
						}
					});
					total = total * invoicePercentage;
					return total + this.setupFeeTotal;
				} else {
					return this.setupFeeTotal;
				}
			},
		},

		watch: {
			selectedPackage: function(val) {
				this.lSelectedPackage = val;
			},

			totalUpfrontAmount: {
				immediate: true,
				handler: function(val) {
					this.$emit('total-upfront-amount', val);
				},
			},
		},

		computed: {
			buttonLabel: function() {
				if (!this.allowMultipleDeliverables) {
					return this.$t('proposal.deliverables.single-package');
				} else if (this.allowMultipleDeliverables && this.forceAllDeliverables) {
					return this.$t('proposal.deliverables.all-packages');
				} else {
					return this.$t('proposal.deliverables.multiple-packages');
				}
			},

			computedTaxRate: function() {
				if (this.$store.state.podAccount) {
					return this.$store.state.podAccount.accountPreferences.defaultTaxRate;
				} else {
					return this.taxRate;
				}
			},

			taxLabel: function() {
				if (this.account.taxLabel) {
					return this.account.taxLabel;
				}

				let gst = ['NZ', 'AU', 'CA', 'IN', 'SG'];
				let tax = ['US'];
				let country = this.account.country;

				if (!country) {
					return this.$t('invoice.details.tax');
				} else if (gst.includes(country)) {
					return this.$t('invoice.details.gst');
				} else if (tax.includes(country)) {
					return this.$t('invoice.details.tax');
				} else {
					return this.$t('invoice.details.vat');
				}
			},

			createPackageAllowed: function() {
				if (this.clientMode) return false;
				let isPaid = this.$store.getters.isPaidAccount;
				let userType = this.$store.getters.getUserType;
				return isPaid && (userType === 'OWNER' || userType === 'FULL_USER' || userType === 'IMPLEMENTER');
			},

			anyDeliverableWithDueDate: function() {
				for (let i = 0; i < this.lDeliverables.length; i++) {
					if (this.lDeliverables[i].dueDate) {
						return true;
					}
					if (
						this.lDeliverables[i].dueDateRelative &&
						this.lDeliverables[i].dueDateRelative.duration &&
						this.lDeliverables[i].dueDateRelative.timeUnit
					) {
						return true;
					}
				}

				return false;
			},

			buttonClass: function() {
				let classList = 'packageSelectButton row-format';

				if (!this.deliverableSelected) {
					classList = classList + ' noSelection';
				} else if (this.selectedPackage) {
					classList = classList + ' selfSelection';
				} else {
					classList = classList + ' otherSelection';
				}

				return classList;
			},

			innerRadioClass: function() {
				if (this.selectedPackage) {
					return 'innerRadio';
				} else {
					return '';
				}
			},

			packageTextClass: function() {
				if (this.selectedPackage) {
					return 'packageSelectText selectedText';
				} else {
					return 'packageSelectText';
				}
			},

			visible: function() {
				// return true;
				if (!this.isLocked) {
					return true;
				} else if (this.templateMode) {
					return true;
				} else if (this.forceAllDeliverables) {
					return true;
				} else if (this.deliverablesCount === 1 || (this.deliverablesCount > 1 && this.lSelectedPackage === true)) {
					return true;
				} else {
					return false;
				}
			},

			bodyStyle: function() {
				return `font-family: ${this.formatting.bodyFont}; text-align:left; font-size: 14px`;
			},

			packageLabel: function() {
				if (this.selectedPackage) {
					return 'Selected';
				} else {
					return 'Select Package';
				}
			},

			deliverableCss: function() {
				if (this.selectedPackage && this.deliverablesCount > 1) {
					return 'deliverableBlock deliverableBlockSelected';
				} else {
					return 'deliverableBlock';
				}
			},

			showDeliverablePricing: function() {
				if ((this.lFeeSchedule && this.lFeeSchedule.feeType === 'Per Item') || this.hasAnySetupTasks) {
					return true;
				} else {
					return false;
				}
			},

			hasAnySetupTasks: function() {
				let d = this.lDeliverables.findIndex((d) => d.initialSetupTask);
				if (d > -1) {
					return true;
				} else {
					return false;
				}
			},

			mainDeliverableColumns: function() {
				let cols = 12;

				if(this.$vuetify.breakpoint.xsOnly){
					return 12;
				}
				if (this.showDeliverablePricing || this.hasAnySetupTasks) {
					cols = cols - 3;
				}
				if (this.anyDeliverableWithDueDate) {
					cols = cols - 2;
				}
				return cols;
			},

			totalUpfrontAmount: function() {
				return this.calculateTotalUpfrontAmount();
			},

			setupFeeTotal: function() {
				let taxPercentage = this.taxRate / 100;
				let total = 0;

				this.lDeliverables.forEach((d) => {
					if (d.initialSetupTask && d.quantity && d.product && d.product.rate) {
						let subTotal = d.quantity * d.product.rate;
						if (d.product.taxable && taxPercentage) {
							subTotal = subTotal + subTotal * taxPercentage;
						}
						total = total + subTotal;
					}
				});

				return total;
			},

			feeSchedulePrimary: function() {
				let currency;
				if (this.currency) {
					currency = this.currency;
				} else {
					currency = this.$store.state.defaultCurrency;
				}

				if (
					(!this.lFeeSchedule ||
						this.lFeeSchedule.amount === undefined ||
						this.lFeeSchedule.amount === null ||
						this.lFeeSchedule.amount === 0) &&
					this.lFeeSchedule.feeType !== 'Per Item'
				) {
					return this.editMode ? '[Set up fees]' : '';
				}

				if (this.lFeeSchedule.feeType === 'Per Item') {
					let total = 0;
					let taxPercentage = this.taxRate / 100;
					this.lDeliverables.forEach((d) => {
						if (!d.initialSetupTask && d.quantity && d.product && d.product.rate) {
							let subTotal = d.quantity * d.product.rate;
							if (d.product.taxable && taxPercentage) {
								subTotal = subTotal + subTotal * taxPercentage;
							}
							total = total + subTotal;
						}
					});
					total = total + this.setupFeeTotal;
					return this.$formatters.dollars(total, true, true, currency);
				}


				let total = parseFloat(this.lFeeSchedule.amount);

				if(this.lFeeSchedule.feeType === 'Fixed Price'){
					total = total + this.setupFeeTotal;
				}

				let amount = this.$formatters.dollars(total, true, true, currency);

				if (this.lFeeSchedule.feeType === 'Hourly') {
					amount = amount + '/hr';
				}

				if (this.lFeeSchedule.taxable && this.taxRate) {
					amount = amount + ` <span class="font-12 font-gray_70">+${this.taxRate}% ${this.taxLabel}</span>`;
				}

				return amount;
			},

			feeScheduleSecondary: function() {
				let currency;
				if (this.currency) {
					currency = this.currency;
				} else {
					currency = this.$store.state.defaultCurrency;
				}

				if (
					!this.lFeeSchedule ||
					this.lFeeSchedule.amount === undefined ||
					this.lFeeSchedule.amount === null ||
					this.lFeeSchedule.amount === 0
				) {
					return '';
				}

				if (this.lFeeSchedule.feeType === 'Hourly') {
					let min = this.lFeeSchedule.estimateMin;
					let max = this.lFeeSchedule.estimateMax;

					if (min && max && min !== max) {
						return `Estimate: ${min}-${max} hours`;
					} else if (min) {
						return `Estimate: ${min} hours`;
					} else if (max) {
						return `Estimate: ${max} hours`;
					} else {
						return '';
					}
				} else if (this.lFeeSchedule.feeType === 'Retainer') {
					let result;
					if (this.lFeeSchedule.retainerSchedule) {
						result = this.lFeeSchedule.retainerSchedule + ' recurring';
					} else {
						result = 'Recurring';
					}
					let min = this.lFeeSchedule.estimateMin;
					let max = this.lFeeSchedule.estimateMax;

					if (min && max && min !== max) {
						result += `: ${min}-${max} hours`;
					} else if (min) {
						result += `: ${min} hours`;
					} else if (max) {
						result += `: ${max} hours`;
					}

					if (max && this.lFeeSchedule.retainerOverageRate) {
						result += `<br>+${this.$formatters.dollars(
							this.lFeeSchedule.retainerOverageRate,
							true,
							true,
							currency
						)}/hr beyond ${max} hours`;
					}

					return result;
				} else {
					return null;
				}
			},

			feeStyle: function() {
				return `font-size: 22px; font-family: ${this.formatting.headerFont}; font-weight: ${this.formatting.headerWeight}; text-align: right`;
			},
		},
	};
</script>

<style lang="scss">
	#proposal-deliverables {
		.feeEditMode {
			border: 1px dotted var(--v-gray_10-base);
			cursor: pointer;

			&:hover {
				background-color: var(--v-gray_10-base);
				color: var(--v-gray_50-base);
			}
		}

		.feeSecondary {
			font-size: 12px;
			color: var(--v-gray_50-base);
		}

		.noSelection {
			background-color: var(--v-gray_20-base);
		}

		.otherSelection {
			background-color: var(--v-gray_20-base);
		}

		.selfSelection {
			background-color: var(--v-gray_20-base);
		}

		.packageSelectButton {
			border-radius: 8px 24px 24px 8px;
			width: 221px;
			height: 48px;
			justify-content: flex-end;
			align-items: center;
		}

		.packageSelectText {
			color: var(--v-gray_80-base);
			font-size: 16px;
			text-align: center;
			margin-right: 15px;
		}

		.selectedText {
			color: var(--v-blue_50-base) !important;
			margin-right: 30px !important;
		}

		.innerRadio {
			width: 20px;
			height: 20px;
			border-radius: 10px;
			background-color: var(--v-blue_50-base);
		}

		.packageSelectRadio {
			width: 32px;
			height: 32px;
			background-color: var(--v-white-base);
			border-radius: 16px;
			border: 2.66667px solid var(--v-gray_50-base);
			margin-right: 10px;
			cursor: pointer;
			justify-content: center;
			align-items: center;
		}

		.deliverableHeading {
			padding: 8px 0px;
			color: var(--v-gray_70-base);
		}

		.deliverableBlock {
			margin-left: 0;
			margin-right: 0;
		}

		.deliverableBlockSelected {
			//box-shadow: 2px 5px 10px #888888;
		}
	}
</style>
