<template>
	<div class="lightbox-overlay" @click.self="close">
		<div class="lightbox-content">
			<button class="close-button" @click="close"><v-icon color="white">close</v-icon></button>
			<div v-if="isPdf" class="pdf-container">
				<iframe :src="url" frameborder="0" height="100%" width="100%" style="min-width:100%; min-height:100%;"></iframe>
			</div>
			<div v-else class="image-container">
				<img :src="url" alt="Lightbox Image" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LightBox",
	props: {
		url: {
			type: String,
			required: true
		},
		fileName: {
			type: String,
			required: true
		}
	},
	computed: {
		// Checks if the URL ends with '.pdf' (case insensitive)
		isPdf() {
			return this.fileName.toLowerCase().endsWith('.pdf');
		}
	},
	methods: {
		close() {
			this.$emit("close");
		}
	}
};
</script>

<style scoped>
.lightbox-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	min-width: 100vw;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.lightbox-content {
	position: relative;
	min-width: 95%;
	min-height: 95%;
	width: 95%;
	height: 95%;
	background: var(--v-black-base);
	overflow: hidden;
	border-radius: 4px;
}

.pdf-container {
	width: 100%;
	height: 100%;
	min-height: 100%;
	min-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.image-container {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.image-container img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	display: block;
}

.pdf-container {
	width: 100%;
	height: 100%;
	min-height: 100%;
	min-width: 100%;
}

.pdf-container iframe {
	display: block;
	width: 100%;
	height: 100%;
	min-height: 100%;
	min-width: 100%;
}

.close-button {
	position: absolute;
	top: 5px;
	right: 5px;
	background: transparent;
	border: none;
	font-size: 20px;
	cursor: pointer;
}
</style>