<template>
	<div class="pa-3" v-if="isReady">
		<div class="row-format pointer" @click="toggleExpanded" style="flex-wrap: wrap">
			<v-container fluid>
        <v-row dense>
          <v-col cols="12" md="6" class="row-format align-center" style="text-align: left">
            <v-icon size="16" class="chevron-icon" color="black">${{ isExpanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
            <div class="brand-medium text-left ml-2">{{ project.name }}</div>

            <div
                v-if="!project.active"
                style="background-color: var(--v-success_10-base); border-radius: 4px"
                class="ml-2 px-1 row-format align-center font-12"
            >
              <v-icon color="success" size="10" class="mr-1">$check</v-icon>
              <div style="color: var(--v-success-base)">{{ $t('projects.completed') }}</div>
            </div>
          </v-col>
          <v-col cols="12" md="6" style="text-align: right">
            <div class="secondary-box">
              <v-btn class="secondary-action">
                <span>{{ $t('global.due') }}: </span>
                <span v-html="formattedDueDate"></span>
                <v-icon size="16" class="ml-2" color="gray_80">$calendarMono</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
		</div>

		<div v-if="isExpanded">
			<div v-if="project.proposalId" class="text-left font-14 font-gray_80 pointer" @click="openProposal()">
				<span class="brand-semilight">{{ $t('projects.from') }} </span>
				<span class="brand-medium">{{ project.proposalName ? project.proposalName : 'Proposal' }}</span>
			</div>

      <div class="my-2"></div>

      <div v-html="project.description" class="text-left"></div>

			<div class="my-2"></div>

      <time-and-money :project="project"></time-and-money>

      <div class="my-4"></div>

      <!--div v-if="project.files">
        <v-container fluid class="py-5 font-14">
          <v-row class="pointer file-row" v-for="file in project.files" :key="file.fileName" align="center"  @click="downloadFile(file)">
            <v-col cols="12" align="left">
              <div class="row-format align-center">
                <img :src="file.fileIconUrl" width="30">
                <div class="ml-2 font-12 brand-medium">{{file.fileName}}</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div-->

			<v-container fluid class="ma-0 pa-0" v-if="pendingDeliverables.length || previousDeliverables.length">
				<v-row dense>
					<v-col cols="12" md="6" align="left">
            <div v-for="pending in pendingDeliverables" :key="pending.statusId">
              <div class="mb-2 font-16 ml-2">{{ pending.label }}</div>
              <div class="row-format" style="flex-wrap: wrap">
                <deliverable-card
                    v-for="deliverable in pending.deliverables"
                    :key="deliverable.id"
                    :status-list="statusList"
                    :deliverable="deliverable"
                    @open-deliverable="openDeliverable(deliverable)"
                ></deliverable-card>
              </div>
            </div>
					</v-col>
					<v-col cols="12" md="6" align="left">
            <div v-for="previous in previousDeliverables" :key="previous.statusId">
              <div class="mb-2 font-16 ml-2">{{ previous.label }}</div>
              <div class="row-format" style="flex-wrap: wrap">
                <deliverable-card
                    v-for="deliverable in previous.deliverables"
                    :key="deliverable.id"
                    :status-list="statusList"
                    :deliverable="deliverable"
                    @open-deliverable="openDeliverable(deliverable)"
                ></deliverable-card>
              </div>
            </div>
					</v-col>
				</v-row>
			</v-container>

      <div v-if="project.portalAccess !== 'Overview'" class="mt-5">
        <domain-root type="Project" :id="project.id" :show-deleted="showDeleted" :read-only="project.portalAccess !== 'Full access'"></domain-root>
      </div>
		</div>
	</div>
</template>

<script>

	import { DateTime } from 'luxon';
	import ProjectService from '@/modules/projects/ProjectService';
	import DeliverableCard from '@/modules/projects/DeliverableCard';
  import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
  import TimeAndMoney from '@/modules/projects/TimeAndMoney';
  import DomainRoot from '@/modules/files/DomainRoot';

	export default {
		name: 'Project',

		props: ['project', 'statusList'],

		components: { DomainRoot, TimeAndMoney, DeliverableCard },

		data: function() {
			return {
        showDeleted: false,
				DateTime: DateTime,
				isExpanded: false,
				projectService: new ProjectService(),
				deliverables: null,
        isReady: false,
			};
		},

		mounted() {
      this.$store.state.eventBus.$on('file-system-toggle-deleted', this.toggleShowDeleted);
			this.getDeliverables();
      if(this.project.initExpanded){
        this.isExpanded = true;
      }
		},

		beforeDestroy() {
      this.$store.state.eventBus.$off('file-system-toggle-deleted', this.toggleShowDeleted);
    },

		methods: {
			getDeliverables: function() {
				this.projectService.getProjectDeliverables(this.project.id).then((res) => {
          this.deliverables = res.data;
				}).finally(() => this.isReady = true);
			},

      toggleShowDeleted: function(){
        this.showDeleted = !this.showDeleted;
      },

			toggleExpanded: function() {
				this.isExpanded = !this.isExpanded;
			},

			openDeliverable: function(deliverable) {
        let binding = {
          origDeliverable: deliverable,
          project: this.project,
          statusList: this.statusList
        }

        this.$store.state.globalModalController
            .openModal(DeliverableDetail, binding,true,false)
            .then(() => this.getDeliverables());
      },

			openProposal: function() {

      },

      populateMap: function(deliverables){
        let result = new Map();
        deliverables.forEach(d => {
          if (!result.has(d.statusId)) {
            result.set(d.statusId, [])
          }
          result.get(d.statusId).push(d);
        });
        return result;
      },

      organizeDeliverables(map){
        let result = [];
        for (let statusId of map.keys()) {
          let statusIx = this.statusList.findIndex(s => s.id === statusId);
          let status = this.statusList[statusIx];
          result.push({
            statusId:statusId,
            label: status.label,
            sort: statusIx,
            deliverables: map.get(statusId)
          });
        }
        result.sort((a,b) => a.sort-b.sort);
        return result;
      }
		},

		computed: {
      pendingDeliverables: function(){
        if(this.deliverables){
          let map = this.populateMap(this.deliverables.pendingApproval);
          return this.organizeDeliverables(map);
        }else{
          return [];
        }
      },

      previousDeliverables: function(){
        if(this.deliverables){
          let map = this.populateMap(this.deliverables.previouslyApproved);
          return this.organizeDeliverables(map);
        }else{
          return [];
        }
      },

      formattedDueDate: function() {
				if (this.project.dueDate) {
					return DateTime.fromISO(this.project.dueDate).toLocaleString(DateTime.DATE_MED);
				} else {
					return `<span class="font-gray_70">${this.$t('projects.no-due-date')}</span>`;
				}
			},

			formattedFees: function() {
				if (this.project.feeSchedule) {
					let amount = 0;

					if (this.project.feeSchedule.amount) {
						amount = this.$formatters.dollars(this.project.feeSchedule.amount, false);
					}

					switch (this.project.feeSchedule.feeType) {
						case 'Hourly': {
							return amount + '/hr';
						}

						case 'Fixed Price': {
							return amount + '/' + this.$t('projects.fixed');
						}

						case 'Retainer': {
							if (this.project.feeSchedule.retainerSchedule) {
								return amount + '/' + this.project.feeSchedule.retainerSchedule.toLowerCase();
							} else {
								return amount + '/?';
							}
						}

						default: {
							return '<span class="font-gray_70">--</span>';
						}
					}
				} else {
					return '<span class="font-gray_70">--</span>';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.deliverable-container {
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		border-radius: 4px;
		margin: 12px 0px;
	}

	.deliverable-row {
		border-bottom: 1px solid var(--v-gray_30-base);
		&:last-child {
			border-bottom: 0px;
			padding-bottom: 0px;
			margin-bottom: -12px;
		}
	}

	.status-wrapper {
		border-bottom: 1px solid var(--v-gray_50-base);
		padding: 24px 0px 24px 0px;
		&:last-child {
			border-bottom: 0px;
		}
	}

	.status-color {
		width: 4px;
		height: 32px;
		border-radius: 0px 2px 2px 0px;
		background-color: var(--status-color);
	}

	.deliverable-count {
		width: 16px;
		height: 16px;
		background-color: var(--v-gray_20-base);
		border-radius: 2px;
		font-size: 12px;
		margin-left: 8px;
	}

	.status-box {
		height: 36px;
		margin-right: 3px;
		border-radius: 2px;
	}
</style>
