<template>
	<div
		:style="`background-color: ${styles.backgroundColor}; width:100%; --font-color: ${styles.fontColor}; --form-font-size: ${styles.fontSize}px; --form-input-color: ${styles.formInputColor}; font-family: '${form.font}'; color: ${styles.fontColor}!important`"
		:class="`${inFrame ? 'outer-wrapper-in-frame' : 'outer-wrapper'} pa-6 column-format align-center main-form`"
		:key="refreshKey" v-if="isReady"
	>
		<div :style="`width:100%; min-width: 300px; max-width: ${styles.maxWidth}px`" v-if="!complete">
			<div v-for="(item, index) in pages[currentPage]" :key="item.id" :id="item.id">
				<item-renderer
					ref="item"
					:index="index + ((currentPage + 1) * 1000)"
					:item="item"
					:styles="styles"
					:font="font"
					:button-props="buttonProps"
					:disabled="disabled"
					:current-page="currentPage + 1"
					:page-count="pages.length"
					:account-logo="accountLogo"
					:active="false"
					:results="results"
					:known-data="defaultContact"
					@next-page="nextPage()"
					@previous-page="previousPage()"
					@input="handleInput($event)"
				></item-renderer>
			</div>
			<div v-if="isLastPage">
				<div class="row-format gap-2 pt-2 px-2" :style="`width: 100%; justify-content: ${justification}`">
					<!--v-btn class="elevation-0" :color="styles.buttonColor" style="font-size: var(--form-font-size)!important" v-if="styles.enableDraftSave" @click="saveForm()">
						<v-icon class="mr-1 material-symbols-outlined" :color="styles.buttonTextColor" v-if="styles.draftIcon">{{ styles.draftIcon }}</v-icon>
						<span :style="`color: ${styles.buttonTextColor}`">{{ styles.saveDraftText }}</span>
					</v-btn-->
					<v-btn
						v-if="pages.length > 1"
						class="elevation-0"
						:color="styles.buttonColor"
						:style="`font-size: var(--form-font-size) !important; ${
							buttonProps.block ? 'width: 49%!important;' : ''
						}`"
						:x-small="buttonProps.xSmall"
						:small="buttonProps.small"
						:large="buttonProps.large"
						:x-large="buttonProps.xLarge"
						@click="previousPage"
					>
						<v-icon class="mr-1" :color="styles.buttonTextColor">{{ styles.prevPageIcon }}</v-icon>
						<span :style="`font-family: ${font}; ; text-transform: none; color: ${styles.buttonTextColor}`">{{ styles.prevPageText }}</span>
					</v-btn>
					<v-btn
						class="elevation-0"
						:style="`font-size: var(--form-font-size) !important; ${
							pages.length > 1 && buttonProps.block ? 'width: 49%!important;' : ''
						}`"
						:color="styles.buttonColor"
						:x-small="buttonProps.xSmall"
						:small="buttonProps.small"
						:large="buttonProps.large"
						:x-large="buttonProps.xLarge"
						:block="pages.length === 1 && buttonProps.block"
						@click="submitForm()"
						:disabled="disabled"
					>
						<span :style="`font-family: ${font}; ; text-transform: none; color: ${styles.buttonTextColor}`">{{ styles.submitText }}</span>
						<v-icon
							class="ml-1 material-symbols-outlined"
							:color="$vuetify.theme.dark ? 'black' : styles.buttonTextColor"
							v-if="styles.submitIcon"
							>{{ styles.submitIcon }}</v-icon
						>
					</v-btn>
				</div>
				<div class="font-10 font-gray_60 mt-8">
					This page is protected by reCAPTCHA and the Google
					<a href="https://policies.google.com/privacy">Privacy Policy</a> and
					<a href="https://policies.google.com/terms">Terms of Service</a> apply.
				</div>
			</div>
		</div>
		<div v-else>
			<div v-html="form.confirmationText"></div>
		</div>
		<v-snackbar v-model="showError" top color="gray_80" :timeout="5000">
			{{ errorText }}
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="showError = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import ItemRenderer from '@/modules/forms/v2/schema/ItemRenderer';
  import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

	export default {
		name: 'FormV2',

		props: ['form', 'font', 'inFrame', 'inPortal', 'complete','disablePartialData'],

		components: { ItemRenderer },

    mixins: [RendererMixin],

		data: function () {
			return {
				customCss: null,
				errorText: null,
				showError: false,
				results: [],
				currentPage: 0,
				refreshKey: 0,
				disabled: false,
				isReady: false,
				defaultContact: null
			};
		},

		mounted() {
			this.loadPartialForm();
			this.setCustomCss();

			let urlParams = new URLSearchParams(window.location.search);

			if (urlParams.get('knownUser') === 'true') {
				this.defaultContact = {
					firstName: urlParams.get('firstName'),
					lastName: urlParams.get('lastName'),
					email: urlParams.get('email'),
					phone: urlParams.get('phone'),
				};
			}else if(this.$store.state.loggedInUser){
        this.defaultContact = {
          firstName: this.$store.state.loggedInUser.firstName,
          lastName: this.$store.state.loggedInUser.lastName,
          email: this.$store.state.loggedInUser.email,
          phone: this.$store.state.loggedInUser.phone,
        };
      }
		},

		beforeDestroy() {
			document.head.removeChild(this.customCss);
		},

		methods: {
			loadPartialForm: function(){
				if(this.disablePartialData){
					this.isReady = true;
					return;
				}

				try {
					let partialResult = localStorage.getItem(`${this.form.accountId}.${this.form.uniqueTemplateId}`);
					if (partialResult) {
						this.results.push(...JSON.parse(partialResult));
					}
				}catch(err){
					console.log(err);
				}
				this.isReady = true;
			},

			setCustomCss: function(){
				if(!this.customCss){
					this.customCss = document.createElement('style');
					document.head.append(this.customCss);
				}

				this.customCss.textContent = this.styles.customCss;
			},

			doValidation: function () {
				let invalidCount = 0;
				for (let i = 0; i < this.$refs.item.length; i++) {
					if (!this.$refs.item[i].validate()) {
						invalidCount++;
					}
				}
				if (invalidCount) {
					return false;
				} else {
					return true;
				}
			},

			submitForm: function () {
				if (!this.doValidation()) {
					this.errorText = 'Please correct the errors in your form';
					this.showError = true;
					return;
				}

				this.results.sort((a, b) => a.index.toString().localeCompare(b.index.toString()));
				
				let formResult = {};
				formResult.answers = [];
				let files = [];

				for (let i = 0; i < this.results.length; i++) {
					let item = this.results[i].item;
					let value = this.results[i].value;
					let container = this.results[i].container;

					console.log('******************************************');
					console.log(item.fieldName);

					if(container && !this.colConditionMet(container)){
						console.log('exiting because container is hidden');
						continue
					}else if(!this.colConditionMet(item)) {
						console.log('exiting because item is hidden');
            continue
          }

					if (item.schemaMapping) {
						formResult[item.schemaMapping] = Array.isArray(value) ? value.join(', ') : value;
						//continue;
					}

					let answer;
					if (item.type === 'FileInput') {
						files.push(...value);
						answer = JSON.stringify(value.map((f) => f.name));
					} else {
						answer = Array.isArray(value) ? value.join(', ') : value;
					}

					formResult.answers.push({
						id: item.id,
						fieldKey: item.fieldName,
						fieldType: item.type,
						question: item.question,
						answer: answer,
					});
				}

				this.$emit('complete', {
					formResult: formResult,
					files: files,
				});
			},

			nextPage: function () {
				if (!this.disabled && !this.doValidation()) {
					this.errorText = 'Please correct the errors in your form';
					this.showError = true;
					return;
				}
				this.currentPage++;
				this.$nextTick(() => this.scrollToTop());
			},

			previousPage: function () {
				this.currentPage--;
				this.$nextTick(() => this.scrollToTop());
			},

			scrollToTop: function(){
				if(this.pages[this.currentPage] && this.pages[this.currentPage].length){
					let id = this.pages[this.currentPage][0].id;
					let div = document.getElementById(id);
					if(div){
						div.scrollIntoView({ block: 'start', behavior: 'smooth' });
					}
				}
			},

			handleInput: function (event) {
				let ix = this.results.findIndex((r) => r.item.id === event.item.id);

				if (ix > -1) {
					this.results.splice(ix, 1, event);
				} else {
					this.results.push(event);
				}

				try {
					localStorage.setItem(`${this.form.accountId}.${this.form.uniqueTemplateId}`, JSON.stringify(this.results));
				}catch(err){
					console.log(err);
				}
			},

			setResults: function(previousResults){
				this.results.push(... previousResults);
				this.refreshKey++;
				this.disabled = true;
			}
		},

		watch: {
			complete: function (val) {
				if (val) {
					if (this.form.confirmationRedirect) {
						if(this.inFrame || this.inPortal){
              if(this.form.confirmationText) {
                setTimeout(() => window.parent.postMessage('[Redirect]' + this.form.confirmationRedirect, '*'),5000);
              }else{
                window.parent.postMessage('[Redirect]' + this.form.confirmationRedirect, '*');
              }
						}else {
              if(this.form.confirmationText){
                setTimeout(() => window.location = this.form.confirmationRedirect,5000);
              }else {
                window.location = this.form.confirmationRedirect;
              }
						}
					}
				}
			},
		},

		computed: {
			isLastPage: function () {
				if (this.currentPage === this.pages.length - 1) {
					return true;
				} else {
					return false;
				}
			},

			pages: function () {
				let pages = [[]];
				for (let i = 0; i < this.schema.length; i++) {
					pages[pages.length - 1].push(this.schema[i]);
					if (this.schema[i].type === 'NewPage') {
						pages.push([]);
					}
				}
				return pages;
			},

			styles: function () {
				return this.form.formSettingsV2;
			},

			schema: function () {
				return this.form.schemaV2;
			},

			buttonProps: function () {
				let result = {
					xSmall: this.styles.buttonSize === 'X-Small',
					small: this.styles.buttonSize === 'Small',
					large: this.styles.buttonSize === 'Large',
					xLarge: this.styles.buttonSize === 'X-Large',
					block: this.styles.buttonStyle === 'Block',
				};

				return result;
			},

			accountLogo: function () {
				if (this.$vuetify.theme.dark && this.form.accountLogoDark) {
					return this.form.accountLogoDark;
				} else {
					return this.form.accountLogo;
				}
			},

			justification: function () {
				if (this.styles.submitAlignment === 'JUSTIFIED') {
					return 'space-between';
				} else if (this.styles.submitAlignment === 'LEFT') {
					return 'flex-start';
				} else if (this.styles.submitAlignment === 'RIGHT') {
					return 'flex-end';
				} else {
					return 'center';
				}
			},
		},
	};
</script>

<style lang="scss">
	.main-form {
		.v-text-field {
			background: var(--form-input-color) !important;
			background-color: var(--form-input-color) !important;
		}
	}

	.outer-wrapper {
		min-height: 100vh;
	}

	.outer-wrapper-in-frame {
	}
</style>
