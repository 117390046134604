<template>
  <div class="container" :style="`--width: ${$vuetify.breakpoint.width*0.8}px`">
    <div class="row-format align-center">
      <v-icon size="20" style="color: #000" class="ml-auto pointer" @click="$emit('result')">$close</v-icon>
    </div>
    <div>
      <iframe :src="finalUrl" :id="`frame-${frameId}`" class="responsive-iframe"></iframe>
    </div>
  </div>
</template>

<script>
	import iFrameResize from 'iframe-resizer/js/iframeResizer';
	import { v4 as uuid4 } from 'uuid';

	export default {
		name: 'MeetingEmbed',

		props: ['frameUrl'],

		components: {},

		data: function() {
			return {
				frameId: uuid4().replaceAll('-', ''),
				resizerOptions: {
					log: false,
					heightCalculationMethod: 'min',
					sizeWidth: true,
					sizeHeight: true,
				},
			};
		},

		mounted() {
			iFrameResize(this.resizerOptions, `#frame-${this.frameId}`);
			window.addEventListener('message', this.handleFrameMessage);
      console.log(this.$store.state.loggedInUser);
		},

		beforeDestroy() {
			window.removeEventListener('message', this.handleFrameMessage);
		},

		methods: {
			handleFrameMessage(e) {
				if (e.data.startsWith('[iFrameSizer]')) {
					return;
				}

				console.log('Frame message received', e);
				this.$emit('frame-event', e);
			},
		},

		computed: {
      finalUrl: function(){
        let result = new URL(this.frameUrl);
        result.searchParams.append('knownUser','true');
        result.searchParams.append('firstName',this.$store.state.loggedInUser.firstName);
        result.searchParams.append('lastName',this.$store.state.loggedInUser.lastName);
        result.searchParams.append('email',this.$store.state.loggedInUser.email);
        result.searchParams.append('phone',this.$store.state.loggedInUser.phone);
        return result.href;
      }
    },
	};
</script>

<style scoped lang="scss">
	.container {
    min-width: var(--width);
    min-height: 500px;
    background-color: #fff;
	}

	.responsive-iframe {
		padding: 0px;
		margin: 0px;
		border: 0;
		max-width: 100%;
		min-width: 100%;
	}
</style>
