<template>
	<v-alert v-model="show" :type="alert.type" tile dense dismissible class="py-1 ma-0 text-center" transition="fade-transition">
		{{ alert.message }}
	</v-alert>
</template>

<script>
	export default {
		name: 'Alert',

		props: {
			alert: {
				type: Object,
				default: function() {
					return {
						message: null,
						type: null,
					};
				},
			},
		},

		data() {
			return {
				show: false,
				timeouts: { error: 0, warning: 10, info: 7, success: 7 },
				timeoutHandler: null,
			};
		},

		watch: {
			alert: function(newAlert) {
				this.$emit('show-alert');
				clearTimeout(this.timeoutHandler);
				this.timeoutHandler = null;

				if (newAlert.type) {
					this.show = true;

					let timeout = this.timeouts[newAlert.type];

					if (timeout > 0) {
						this.timeoutHandler = setTimeout(this.clearAlert, timeout * 1000);
					}
				} else {
					this.show = false;
				}
			},
		},

		mounted() {},

		methods: {
			clearAlert() {
				this.$emit('hide-alert');
				this.show = false;
				this.alert.message = null;
				this.alert.type = null;
			},
		},

		computed: {},
	};
</script>

<style lang="scss"></style>
