<template>
	<div
		style="width: 700px; min-width: 700px; max-width: 700px; background-color: var(--v-white-base)"
		class="column-format gap-2"
		id="package-editor"
	>
		<div class="row-format gap-1 align-center pa-3">
			<div class="brand-medium">Edit package</div>
			<div class="ml-auto"><v-icon class="pointer" @click="handleClose()">close</v-icon></div>
		</div>
		<div class="column-format gap-2 pa-3">
			<div class="row-format gap-2">
				<v-text-field
					hide-details
					class="equal-size"
					dense
					outlined
					persistent-placeholder
					label="Package name"
					v-model="element.packageName"
				></v-text-field>
				<div class="row-format gap-2 equal-size">
					<date-selector
						class="equal-size"
						:standard="true"
						label="Start date (optional)"
						:date="element.startDate"
						@change="element.startDate = $event"
					></date-selector>
					<v-select
						v-if="element.fees.feeType === 'Retainer'"
						class="equal-size"
						:items="periods"
						v-model="element.fees.retainerPeriods"
						hide-details
						persistent-placeholder
						dense
						outlined
						item-text="label"
						item-value="value"
						label="Billable periods"
					>
					</v-select>
				</div>
			</div>
			<div class="row-format gap-2">
				<v-select
					class="equal-size"
					:items="feeTypes"
					v-model="element.fees.feeType"
					hide-details
					persistent-placeholder
					item-text="label"
					item-value="value"
					dense
					outlined
					label="Fee type"
				>
				</v-select>
				<div class="equal-size row-format gap-2" v-if="element.fees.feeType === 'Retainer'">
					<v-select
						class="equal-size"
						:items="schedule"
						v-model="element.fees.retainerSchedule"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Recurring period"
					>
					</v-select>
					<v-select
						class="equal-size"
						:items="['Advanced', 'Arrears']"
						v-model="element.fees.retainerTiming"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Recurring timing"
					>
						<template v-slot:item="{ item }">
							<span>{{ item }}</span>
						</template>
					</v-select>
				</div>
			</div>

			<div class="row-format gap-2" v-if="element.fees.feeType !== 'Per Item'">
				<v-text-field
					class="equal-size"
					v-model="element.fees.estimateMin"
					label="Hour estimate (min)"
					hide-details
					persistent-placeholder
					dense
					outlined
					type="number"
					min="0"
					max="10000"
					@change="validateMinMax('min')"
				></v-text-field>

				<v-text-field
					class="equal-size"
					v-model="element.fees.estimateMax"
					label="Hour estimate (max)"
					hide-details
					persistent-placeholder
					dense
					outlined
					type="number"
					min="0"
					max="10000"
					@change="validateMinMax('max')"
				></v-text-field>
			</div>

			<div class="row-format gap-2">
				<v-text-field
					v-if="element.fees.feeType !== 'Per Item'"
					class="equal-size"
					v-model="element.fees.amount"
					:prefix="currencySymbol"
					hide-details
					persistent-placeholder
					dense
					outlined
					oninput="if(this.value < 0) this.value = this.value*-1;"
					type="number"
					label="Amount"
				></v-text-field>
				<v-text-field
					class="equal-size"
					v-if="element.fees.feeType === 'Retainer' && element.fees.estimateMax"
					v-model="element.fees.retainerOverageRate"
					:prefix="currencySymbol"
					hide-details
					persistent-placeholder
					dense
					outlined
					oninput="if(this.value < 0) this.value = this.value*-1;"
					type="number"
					label="Overage hourly amount"
				></v-text-field>
				<v-text-field
					class="equal-size"
					v-if="element.fees.feeType === 'Hourly'"
					v-model="element.depositAmount"
					suffix="%"
					hide-details
					persistent-placeholder
					dense
					outlined
					oninput="if(this.value < 0){ this.value = this.value*-1}else if(this.value > 100){this.value = 100}"
					type="number"
					:label="`Deposit (${depositAmount})`"
				></v-text-field>
			</div>
			<v-checkbox
				dense
				hide-details
				persistent-placeholder
				:true-value="true"
				:false-value="false"
				v-model="element.fees.taxable"
				label="Taxable"
			></v-checkbox>

			<div class="row-format align-center gap-2" v-if="element.fees.feeType !== 'Per Item'">
				<v-select
					v-model="element.overrideDeliverablePackageId"
					:items="packageList"
					item-text="name"
					item-value="id"
					hide-details
					dense
					persistent-placeholder
					outlined
					clearable
					label="Use tasks from project template"
				>
				</v-select>
				<v-icon
					class="ml-auto pointer"
					color="accent"
					v-tippy="{
						content:
							'Selecting this value will replace the service items specified in the agreement with tasks from the specified project template when the project is automatically created.',
					}"
					>help</v-icon
				>
			</div>

			<v-select
				v-if="!element.overrideDeliverablePackageId"
				v-model="element.projectTypeId"
				:items="projectTypes"
				item-text="name"
				item-value="id"
				hide-details
				dense
				persistent-placeholder
				outlined
				clearable
				label="Project type"
				placeholder="Default project type..."
			>
			</v-select>

			<v-text-field
				hide-details
				dense
				outlined
				persistent-placeholder
				label="Button text"
				v-model="element.buttonText"
			></v-text-field>
		</div>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="mt-2 px-2 pt-2 pb-4 row-format gap-2 centered">
			<v-btn icon class="delete" @click="confirmDelete">
				<v-icon>$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action" width="160" @click="save">
				{{ $t('global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ConfirmModal from '@/components/ConfirmModal';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';
	import DateSelector from '@/components/DateSelector';
	import EmbeddedServices from '@/modules/agreements/schema/EmbeddedServices';

	export default {
		name: 'PackageEditor',

		props: ['agreement', 'services', 'servicePackage'],

		mixins: [AgreementMixin],

		components: { DateSelector },

		data: function() {
			return {
				embeddedServices: new EmbeddedServices(),

				element: { ...this.servicePackage },
				packageList: [],

				feeTypes: [
					{ label: 'Hourly', value: 'Hourly' },
					{ label: 'Fixed Price', value: 'Fixed Price' },
					{ label: 'Recurring', value: 'Retainer' },
					{ label: 'Per Item', value: 'Per Item' },
				],

				schedule: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'],
			};
		},

		mounted() {
			this.getPackageList();
		},

		beforeDestroy() {},

		methods: {
			getPackageList: function() {
				this.embeddedServices.getDeliverablePackages().then((res) => {
					this.packageList.splice(0);
					this.packageList.push(...res.data);
				});
			},

			validateMinMax: function(minOrMax) {
				let val;

				if (minOrMax === 'min') {
					val = this.element.fees.estimateMin;
				} else {
					val = this.element.fees.estimateMax;
				}

				if (!val) {
					return;
				}

				val = parseInt(val, 10);

				if (val < 0) {
					val = 0;
				} else if (val > 10000) {
					val = 10000;
				}

				if (minOrMax === 'min') {
					this.element.fees.estimateMin = val;
				} else {
					this.element.fees.estimateMax = val;
				}
			},

			handleClose: function() {
				this.save();
			},

			save: function() {
				this.element.description = this.servicePackage.description;
				Object.assign(this.servicePackage, this.element);
				this.$emit('close');
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this package?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$emit('delete', this.element);
					}
				});
			},
		},

		computed: {
			periodsLabel: function() {
				let val = this.element.fees.retainerSchedule;
				if (val === 'Weekly') {
					return 'weeks';
				} else if (val === 'Bi-Weekly') {
					return 'two week periods';
				} else if (val === 'Monthly') {
					return 'months';
				} else if (val === 'Quarterly') {
					return 'quarters';
				} else if (val === 'Bi-Annually') {
					return 'six month periods';
				} else if (val === 'Annually') {
					return 'years';
				} else {
					return '?';
				}
			},

			periods: function() {
				let result = [];
				result.push({ label: 'Until complete', value: -1 });
				for (let i = 1; i <= 104; i++) {
					result.push({ label: `${i} ${this.periodsLabel}`, value: i });
				}
				return result;
			},

			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.agreement.currency);
			},

			projectTypes: function() {
				if (this.$store.state.projectTypes) {
					return this.$store.state.projectTypes;
				} else {
					return [];
				}
			},

			depositAmount: function() {
				if (this.element.fees.feeType === 'Hourly') {
					return this.$formatters.dollars(
						this.getHourlyDepositAmount(this.element),
						true,
						true,
						this.agreement.currency
					);
				} else {
					return this.$formatters.dollars(
						this.getPerItemDepositAmount(this.element),
						true,
						true,
						this.agreement.currency
					);
				}
			},
		},
	};
</script>

<style lang="scss">
	.override-menu {
		top: var(--menuY) !important;
		left: var(--menuX) !important;
		position: absolute !important;
	}
</style>

<style scoped lang="scss">
	.equal-size {
		flex-grow: 1;
		flex-basis: 0;
		min-width: 0;
	}
</style>
