<template>
	<ul class="detail-nav">
		<li
				v-for="item in computedItems"
				:key="item.index"
				v-scroll-to="{ el: scrollAnchorIdPrefix + item.index, offset: scrollOffset }"
				:class="'d-flex pt-2 pb-2 pl-1 ' + (selectedIndex == item.index ? 'selected' : '')"
				@click="selectMe(item.index)"
		>
			<div class="icon" v-if="item.icon">
				<v-icon v-text="'$vuetify.icons.' + item.icon" class="nav-icon"></v-icon>
			</div>
			<div class="text" v-html="item.label">
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'HNav',
	props: {
		items: Array,
		selected: Number,
		scrollAnchorIdPrefix: { type: String, default: '#card-anchor-' },
		scrollOffset: { type: Number, default: -65 },
	},

	data() {
		return {
			selectedIndex: null,
		};
	},

	mounted() {
		this.selectedIndex = this.selected;
	},

	methods: {
		selectMe(i) {
			this.selectedIndex = i;
		},
	},

	computed: {
		computedItems: function(){
			let result = [];

			for(let i=0; i < this.items.length; i++){
				if(!this.$validations.isEmpty(this.items[i].label)){
					result.push({
						label: this.items[i].label,
						icon: this.items[i].icon,
						index: i
					});
				}
			}

			return(result);
		}
	},

	watch: {
		selected: function(selectedNew) {
			this.selectedIndex = selectedNew;
		},
	},
};
</script>

<style lang="scss" scoped>
ul {
	margin-top: 32px;
	width: 100%;
	padding-left: 0;
	li {
		margin-top: 5px;
		padding-top: 9px;
		padding-left: 4px;
		list-style-type: none;
		border-radius: 8px;
		cursor: pointer;
		color: var(--v-grey-base);
		div.icon {
			width: 32px;
			padding-right: 8px;
			color: var(--v-blue_40-base);
			padding-left: 1px;
		}
		div.text {
			padding-top: 3px;
			font-size: 16px;
			font-family: stratos-semibold;
		}
		&.selected {
			color: var(--v-primary-base);
			.nav-icon {
				color: var(--v-primary-base);
			}
		}
		&:hover {
			background-color: var(--v-carrara_50-base);
			color: var(--v-blue_40-base);
			.nav-icon {
				color: var(--v-blue_40-base);
			}
		}
	}
}
</style>
